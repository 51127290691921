import React from "react";
import Typography from "../../components/ui/typography";
import Button from "../../components/ui/button";
import classNames from "./manage.module.css";

export const SeasonEnding = () => {
  return (
    <div
      className={"card "}
      style={{
        padding: "25px 25px",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      
      }}
    >
      {/* <p style={{ color: "white", fontSize: 20 }}>DEĞERLİ KATILIMCIMIZ</p> */}
      {/*
      <p style={{ color: "white", fontSize: 20 }}>
        "Türkiye Okullar Arası Zeka Oyunları Şampiyonası"na ilişkin görüş ve
        önerilerinizi almak isteriz.
      </p>
      <p style={{ color: "white", fontSize: 20 }}>
        Anket sonuçları, düzenlemiş olduğumuz çalışmamızın geliştirilmesine ve
        yeni dönemdeki çalışmalarımızın oluşturulmasına katkı sağlayacaktır.
      </p>
      <p style={{ color: "white", fontSize: 20 }}>
        İlginiz için teşekkür ederiz.
      </p>

      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSeM6LbROvbeI_I0dQTujuuCXhOEBM2QoaWCXsjL8RSGArCDDw/viewform"
        target="blank"
      >
        <Button
          style={{
            background: "green",
            width: "auto",
            padding: "6px 6px",
            fontSize: 20,
          }}
        >
          Ankete Katılmak İçin Tıklayınız
        </Button>
      </a> */}
      {/* <p style={{ color: "white", fontSize: 20 }}>
        Bu yıl 28.si düzenlenen ve tamamen bireysel olarak yarışabileceğiniz
        OYUN 2023 yarışmamıza bütün öğrenci ve öğretmenlerimizi bekleriz.
      </p> */}
      {/* <a href="https://oyun.tzv.org.tr/" target="blank">
        <img style={{ marginTop: 10 }} src="/assets/images/oyun.png" />
      </a> */}
      {/* <a href="https://www.kongre.tzv.org.tr/2023/" target="blank">
        <img style={{ marginTop: 10, marginLeft: 10 }} src="/assets/images/kongre.png" />
      </a> */}
      <div className={classNames.banner}>
        <a href="https://market.tzv.org.tr/#/products/1065" target="blank">
        <img style={{ marginTop: 10, marginLeft: 10 }} src="/assets/images/yesbox-nedir.png" />
      </a>
      <a href="https://akiloyunlari.griceviz.com/" target="blank">
        <img className={classNames.zeka_banner} style={{ marginTop: 10, marginLeft: 10 }} src="/assets/images/zekaoyunlari-griceviz.png" />
      </a>
      </div>
      
      {/* <a href="https://oyun.tzv.org.tr/" target="blank">
        <Button
          style={{
            background: "green",
            width: "auto",
            padding: "6px 6px",
            fontSize: 20,
            marginTop: "20px",
          }}
        >
          Oyun 2023 Sayfasına Ulaşmak İçin Tıklayınız
        </Button>
      </a> */}
    </div>
  );
};


