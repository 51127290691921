import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Typography from "../../components/ui/typography";
import React, { useState } from "react";
import classNames from "./style.module.css";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const topButtonsValues = [
  { text: "BASIN BÜLTENİ", value: 0 },
  { text: "DERECE ALAN OKULLAR", value: 1 },
  { text: "FOTOĞRAF GALERİSİ", value: 2 },
];

export default () => {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const topButtons = (
    <ButtonGroup
      size="large"
      color="secondary"
      aria-label="large outlined primary button group"
    >
      {topButtonsValues.map((b) => {
        return (
          <Button
            onClick={() => setSelectedMenu(b.value)}
            key={b.value + "topButton"}
            style={{ color: "white", fontWeight: 200 }}
          >
            {b.text}
          </Button>
        );
      })}
    </ButtonGroup>
  );

  return (
    <div className={classNames.pageContainer}>
      {topButtons}
      {selectedMenu === 0 && <Bulten />}
      {selectedMenu === 1 && <MedahallOfFame />}
      {selectedMenu === 2 && <PhotoGallery />}
    </div>
  );
};

const MedahallOfFame = () => {
  const generateMedal = ({
    medalType,
    schoolLogoPath,
    schoolName,
    studentList,
    tutorList,
  }) => {
    const silverMedal = (
      <img src="/assets/images/medals/silver.png" alt="silver" />
    );
    const goldMedal = <img src="/assets/images/medals/gold.png" alt="gold" />;
    const bronzeMedal = (
      <img src="/assets/images/medals/bronze.png" alt="bronze" />
    );

    const medalImage =
      medalType === "gold"
        ? goldMedal
        : medalType === "silver"
        ? silverMedal
        : bronzeMedal;

    const schoolLogoImage = (
      <div>
        <img
          src={schoolLogoPath ? schoolLogoPath : "/assets/images/meb-logo.png"}
          alt="home-logo"
          width="120"
          height="120"
        />
      </div>
    );

    const schoolNameSpan = (
      <div
        style={{
          marginTop: 30,
          fontSize: "24px",
          fontWeight: "bolder",
          color: "#15273A",
          textTransform: "capitalize",
        }}
      >
        {schoolName.toLocaleLowerCase()}
      </div>
    );

    const studentListBox = (
      <div style={{ marginTop: 15 }}>
        <div style={{ fontSize: "24px", color: "#E79121" }}>Takım</div>
        {studentList.map((s) => (
          <div style={{ fontSize: "20px", textTransform: "capitalize" }}>
            {s.toLocaleLowerCase()}
          </div>
        ))}
      </div>
    );

    const tutorListBox = (
      <div style={{ marginTop: 15 }}>
        <div style={{ fontSize: "24px", color: "#E79121" }}>
          Sorumlu Öğretmen
        </div>
        {tutorList.map((t) => (
          <div style={{ fontSize: "20px", textTransform: "capitalize" }}>
            {t.toLocaleLowerCase()}
          </div>
        ))}
      </div>
    );
    return (
      <div
        style={{
          borderRadius: "15px",
          position: "relative",
          width: 339,
          height: 633,
          textAlign: "center",
          lineHeight: 1.1,
          margin: 10,
        }}
      >
        {medalImage}
        <div
          style={{
            position: "absolute",
            top: 150,
            textAlign: "center",
            width: 338,
            paddingLeft: 27,
            paddingRight: 27,
          }}
        >
          {schoolLogoImage}
          {schoolNameSpan}
          {studentListBox}
          {tutorListBox}
        </div>
      </div>
    );
  };

  const generateMedalGroup = ({
    groupName,
    goldMedal,
    silverMedal,
    bronzeMedal,
  }) => {
    const header = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "42px",
          paddingBottom: 20,
          borderBottom: "solid #DA571A 2px",
          marginBottom: 20,
          lineHeight: 1,
        }}
      >
        <span style={{ color: "#DA571A", textAlign: "left" }}>
          Türkiye Finalleri Sonuçları
        </span>
        <span style={{ color: "#15273A", fontWeight: "bold", marginLeft: 30 }}>
          {groupName}
        </span>
      </div>
    );

    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 15,
          padding: 20,
          maxWidth: 1200,
          margin: 20,
        }}
      >
        {header}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {goldMedal}
          {silverMedal}
          {bronzeMedal}
        </div>
      </div>
    );
  };

  //ÜÇÜNCÜ SINIF
  const ucuncuSinifMedals = generateMedalGroup({
    groupName: "Üçüncü Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F3_1_Sehit%20Onur%20%C4%B0lkhan.jpg?alt=media&token=f57f85fd-7f0a-4776-8593-bfc265aed14e",
      schoolName: "ŞEHİT ONUR İLKHAN İLKOKULU",
      studentList: [
        "Elif Yaşar",
        "Umay Su Hayta",
        "Yağız Gök",
        "Umut Güney",
        "Öykü Selin Turan",
      ],
      tutorList: ["Nur Solakoğlu"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F3_2_MEV.png?alt=media&token=79679dd5-af0e-4d4e-8a8e-60a34dd519ad",
      schoolName: "MEV Koleji Özel Ankara İlkokulu",
      studentList: ["Alp Yıldız"],
      tutorList: ["Nagihan Yılmaz"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F3_3_Adana%20Bilsem.png?alt=media&token=d7d6b747-2a19-42d2-b80d-f0ac652e6ff7",
      schoolName: "Adana Bilim ve Sanat Merkezi",
      studentList: ["Ege İnce", "Azra Bozdoğan"],
      tutorList: ["Dilşad Ergen"],
    }),
  });

  ///DÖRDÜNCÜ SINIF
  const dorduncuSinifMedals = generateMedalGroup({
    groupName: "Dördüncü Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "Adana Tabipler Odası İlkokulu",
      studentList: [
        "Ege Karslı",
        "Rıfkı Emir Yalçın",
        "Hüma Önal",
        "Deniz Çığ",
        "Ali Altınok",
        "Deniz Mahmut Çolak",
      ],
      tutorList: ["Mehmet Can"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F3_3_Adana%20Bilsem.png?alt=media&token=d7d6b747-2a19-42d2-b80d-f0ac652e6ff7",
      schoolName: "Adana Bilim ve Sanat Merkezi",
      studentList: ["Göktuğ Kuru", "Jan Demircan", "Asya Atar"],
      tutorList: ["Dilşad Ergen"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F4_3_Pendik%20Merkez.jpg?alt=media&token=d0dff3fb-f715-4ffe-8d58-9f4f1d7d8062",
      schoolName: "Pendik Merkez İlkokulu",
      studentList: [
        "Mert Eren Yeşil",
        "Baran Tuğra Genç",
        "Ömer Osman Kale",
        "Tuna Sırma",
      ],
      tutorList: ["Meryem Elif Acar"],
    }),
  });

  //// BEŞİNCİ SINIF
  const besinciSinifMedals = generateMedalGroup({
    groupName: "Beşinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F5_1_TED.jpg?alt=media&token=9c0bf1d2-3a57-4511-89db-7a6f7d7984fb",
      schoolName: "TED Ankara Koleji Vakfı Özel Ortaokulu",
      studentList: [
        "ilke Çağla Çabuk",
        "Ecem Duru Çelik",
        "Arda Tunay Çelik",
        "Zeynep Akkocaoğlu",
        "Eda Mertyürek",
        "Elif Gündoğdu",
      ],
      tutorList: ["Yasemin Yılmaz", "Ferit Bakan"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F5_2_samsun_rotary.jpg?alt=media&token=8f98805e-92f2-4712-967b-3ad49d7fddb3",
      schoolName: "SAMSUN ROTARY KULÜBÜ BİLİM VE SANAT MERKEZİ",
      studentList: [
        "Beril Su Turan",
        "EMİR ATABEY",
        "Ahmet Efe Yurtseven",
        "Hasan Alp Burtaçoğlu",
        "Enes Demir",
        "Çağan Suat Acaralp",
      ],
      tutorList: ["sinem kant", "Esra İnan"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F5_3_TED%20Kocaeli.png?alt=media&token=3686d3d2-fc3b-4e43-a1aa-9dc933a96e00",
      schoolName: "TED KOCAELİ ÖZEL ORTAOKULU",
      studentList: [
        "defnenil güven",
        "Alp Emre Taş",
        "Doruk SALMAN",
        "Hasan Şerafettin Pala",
        "BEREN BİÇER",
        "Ozan Çınar Oğuz",
      ],
      tutorList: ["ALEV GÖKÇEN ALPAR", "Merve YILMAZ"],
    }),
  });

  //ALTINCI SINIF
  const altinciSinifMedals = generateMedalGroup({
    groupName: "Altıncı Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "TOKİ KAZIM KARABEKİR ORTAOKULU",
      studentList: ["Semih Cihangir", "Zerya Bimay"],
      tutorList: ["fatma gezer", "Burhan Sincar"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "HAYIRSEVERLER ORTAOKULU",
      studentList: [
        "İlkim Eliz Ülker",
        "Özge Canbolat",
        "Ada Bardakçı",
        "Ömer Demirtaş",
        "Zeynep Ekin Yılmaz",
        "Özge Kavuklu",
      ],
      tutorList: ["Neşe Akca"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F6_3_%C4%B0SABET.png?alt=media&token=44e9e098-fa01-4023-9ec1-a441c2f9a541",
      schoolName: "ÖZEL MAHMUDİYE İSABET ORTAOKULU",
      studentList: [
        "Zeynep Bilge KAPLAN",
        "ahsen özbey",
        "Zehra Kaya",
        "ENES MAHMUT ÖZKANALICI",
      ],
      tutorList: ["Bahar Günay", "FUNDA AYDINLAR"],
    }),
  });

  //YEDİNCİ SINIF
  const yedinciSinifMedals = generateMedalGroup({
    groupName: "Yedinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "ÖZEL EYÜBOĞLU ÇAMLICA ORTAOKULU",
      studentList: ["Batuhan Kal", "Ege Yılmaz"],
      tutorList: ["İsmet Baykal"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F5_1_TED.jpg?alt=media&token=9c0bf1d2-3a57-4511-89db-7a6f7d7984fb",
      schoolName: "TED ANKARA KOLEJİ VAKFI ÖZEL ORTAOKULU",
      studentList: [
        "DEREN BOSTAN",
        "Yankı Karadağ",
        "Doruk Sancar",
        "BATU KILIÇ",
        "Doğan Yiğit Bayram",
        "ERAY KARGI",
      ],
      tutorList: ["YASEMİN YILMAZ", "ferit Bakan"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F7_3_ALTINYILDIZ.jpg?alt=media&token=7d67623a-70eb-44ce-afa4-69c2ba1e91b2",
      schoolName: "ÖZEL ALTINYILDIZ ORTAOKULU",
      studentList: ["Berkay Nuray", "İpek Barış", "Fuat Altıok"],
      tutorList: ["ismail ışık"],
    }),
  });

  //SEKİZİNCİ SINIF
  const sekizinciSinifMedals = generateMedalGroup({
    groupName: "Sekizinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "ÖZEL EYÜBOĞLU ÇAMLICA ORTAOKULU",
      studentList: ["Ege Berispek", "Koray Bradley Callan", "Güçlü Ada Dönmez"],
      tutorList: ["İsmet Baykal"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F5_1_TED.jpg?alt=media&token=9c0bf1d2-3a57-4511-89db-7a6f7d7984fb",
      schoolName: "TED ANKARA KOLEJİ VAKFI ÖZEL ORTAOKULU",
      studentList: [
        "Erdem Sönmez",
        "Efe Ömer Arslan",
        "Kerem Cem Yılmaz",
        "Atalay Mumcuoğlu",
        "Alya Gürses",
        "Sönmez Alp Işık",
      ],
      tutorList: ["YASEMİN YILMAZ", "ferit Bakan"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F6_3_%C4%B0SABET.png?alt=media&token=44e9e098-fa01-4023-9ec1-a441c2f9a541",
      schoolName: "ÖZEL MAHMUDİYE İSABET ORTAOKULU",
      studentList: [
        "abdulhamit özbey",
        "Mustafa Karasakal",
        "ELİF BEYZA BALTAŞ",
        "Salih efe Ergün",
      ],
      tutorList: ["Bahar Günay", "FUNDA AYDINLAR"],
    }),
  });

  //DOKUZUNCU SINIF
  const dokuzuncuSinifMedals = generateMedalGroup({
    groupName: "Dokuzuncu Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "ÖZEL PROFESYONEL EĞİTİM ANADOLU LİSESİ",
      studentList: [
        "Özge Alp",
        "YİĞİT SERT",
        "Mehmet Özer",
        "Asude Berra Boztaş",
      ],
      tutorList: [],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "ÖZEL PROFESYONEL EĞİTİM FEN LİSESİ",
      studentList: [
        "Ayşe Torun",
        "Elif Güleç",
        "begüm öztürk",
        "GÖKALP YARAR",
        "Tahsin Sağlam",
        "Yiğit Altınöz",
      ],
      tutorList: ["BETÜL DİNÇLER"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F9_3_Fuat%20Sezgin%20Bilsem.jpg?alt=media&token=d7ce1177-28c2-45e3-84d1-8728d798796f",
      schoolName: "İSTANBUL FUAT SEZGİN BİLİM VE SANAT MERKEZİ",
      studentList: [
        "Kübra Efnan Dilmaç",
        "Beyza Ebrar Dilmaç",
        "Su ece Boztepe",
        "Bersu Berker",
        "Alper Boru",
        "Deniz Erçetin",
      ],
      tutorList: ["yudum özer"],
    }),
  });

  //ONUNCU SINIF
  const onuncuSinifMedals = generateMedalGroup({
    groupName: "Onuncu Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F10_1_Ankara%20Fen%20Lisesi.png?alt=media&token=d18813f6-8851-4931-a0fd-f0c62ecc2ba0",
      schoolName: "ANKARA FEN LİSESİ",
      studentList: [
        "İrem Gülce Yazgan",
        "Melda Rana Alaşahin",
        "Sefa Eren Bulguroğlu",
        "Dilferah Aybir",
        "Zeynep Selin KURAN",
      ],
      tutorList: ["Ekrem Yılmaz"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F10_2_enka.png?alt=media&token=4dd7dfa9-ccb2-47f7-8e5c-1b872e0961e5",
      schoolName: "ÖZEL ENKA MESLEKİ VE TEKNİK ANADOLU LİSESİ",
      studentList: [
        "Barış Can Uslu",
        "Zeynep Nalan Kaya",
        "Yusuf Rıza ALTUĞ",
        "ahmet kağan öztürk",
        "Karhan Akın",
        "Zeliha Beyza KİRLİOĞLU",
      ],
      tutorList: ["huseyin özcan aslan"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F10_2_enka.png?alt=media&token=4dd7dfa9-ccb2-47f7-8e5c-1b872e0961e5",
      schoolName: "ÖZEL ENKA FEN VE TEKNOLOJİ LİSESİ",
      studentList: ["Mete Gökhan Coşkun", "Can Bastemir", "Efe Kaan Keremoğlu"],
      tutorList: ["huseyin özcan aslan"],
    }),
  });

  //ONBİRİNCİ SINIF
  const onbirinciSinifMedals = generateMedalGroup({
    groupName: "On Birinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "ÖZEL BURÇAK EYÜBOĞLU FEN VE TEKNOLOJİ LİSESİ",
      studentList: ["Cengizhan Sertaç ARPACI", "ARDA ÖZCAN", "KEREM AYDOĞAN"],
      tutorList: ["İsmet Baykal"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "ÖZEL EYÜBOĞLU KOLEJİ",
      studentList: ["Güney Bayındır"],
      tutorList: ["İsmet Baykal"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F11_3_hatay%20fen%20lisesi.jpg?alt=media&token=53c42d5b-15c8-4dd1-8788-ad58b6b31163",
      schoolName: "HATAY FEN LİSESİ",
      studentList: [
        "Çağla Doğa Duran",
        "Arif Arda DURAN",
        "Mustafa Yusuf İnal",
        "Mehmet Efe Karabatak",
        "Zerda Acarca",
        "Elena Kudret",
      ],
      tutorList: ["Hülya karabatak"],
    }),
  });

  //ONİKİNCİ SINIF
  const onikinciSinifMedals = generateMedalGroup({
    groupName: "On İkinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fderece_alan_okul_logolari%2F12_1_ted_atakent.png?alt=media&token=f141aa91-aef5-44d0-b07f-01d41a0eb578",
      schoolName: "TED ÖZEL ATAKENT ANADOLU LİSESİ",
      studentList: [
        "Beratcan Özkan",
        "Burak ENÖZ",
        "Kaan Kılıç",
        "Ata Mert Görkem",
      ],
      tutorList: ["Cem Gürses", "serkan TAZEGÜL"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "REKABET KURUMU CUMHURİYET FEN LİSESİ",
      studentList: [
        "Muhammed Yusuf Özkorkmaz",
        "Muhammed Yıldırm",
        "Ahyed Numan TUNÇ",
        "Mehmed Salih Seyitoğlu",
        "Taha Çali",
        "FURKAN DALKILIÇ",
      ],
      tutorList: ["SABİRE DİLEK YENİCELİ"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "",
      schoolName: "UŞAK BİLİM VE SANAT MERKEZİ",
      studentList: ["Semih Zorlu", "İrem ALTINKURT"],
      tutorList: ["EMRE GÜLMEZ"],
    }),
  });
  return (
    <div>
      {ucuncuSinifMedals}
      {dorduncuSinifMedals}
      {besinciSinifMedals}
      {altinciSinifMedals}
      {yedinciSinifMedals}
      {sekizinciSinifMedals}
      {dokuzuncuSinifMedals}
      {onuncuSinifMedals}
      {onbirinciSinifMedals}
      {onikinciSinifMedals}
    </div>
  );
};

const PhotoGallery = () => {
  return (
    <div style={{ marginTop: "30px" }}>
      <a target="_blank" href="https://halici.pixieset.com/tozos_2021_22/">
        <Button size="large" color="secondary" variant="contained">
          GALERİYE ULAŞMAK İÇİN TIKLAYINIZ
        </Button>
      </a>
    </div>
  );
};

const Bulten = () => {
  return (
    <div
      className={"card " + classNames.long_description}
      style={{ fontSize: "medium" }}
    >
      <h4 style={{ fontWeight: "bold", marginTop: 30 }}>
        Türkiye Okullar Arası Zeka Oyunları Şampiyonası’nın Ödül Töreni Başkent
        Öğretmenevi’nde Yapıldı!
      </h4>
      <div
        className={"card " + classNames.sponsor_card}
        style={{ marginTop: 0 }}
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/nUiVOzOjMu4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <p>
        Türkiye Zeka Vakfının, Millî Eğitim Bakanlığı ve TÜBİTAK’ın destekleri
        ile 2019 yılında başlattığı ve bu sene EasyVit ana sponsorluğunda
        gerçekleştirilen “Türkiye Okullar Arası Zeka Oyunları Şampiyonası”nın
        ödül töreni 4 Haziran Cumartesi günü Başkent Öğretmenevi'nde yapıldı.
      </p>
      <h5 style={{ fontWeight: "bold" }}>
        24 Bin 594 Başvuru, 3707 Finalist...
      </h5>
      <p>
        Rekor katılımın olduğu Şampiyona’ya, öğrenci ve öğretmenlerden oluşan
        toplam 24 bin 594 başvuru oldu. Bu sene de 81 ilden de katılım oldu!
      </p>
      <p>
        İlkokul, ortaokul ve lise kategorisinde toplam on sınıf üzerinden
        gerçekleştirilen Şampiyona’ya en çok katılımın olduğu üç bölge
        sırasıyla; Marmara, İç Anadolu ve Ege Bölgesi oldu.
      </p>
      <p>
        <b>Türkiye Zeka Vakfı Başkanı Emrehan Halıcı</b> konuşmasında;
        teknolojideki gelişmelerin bir yandan hayatımıza kolaylıklar ve
        yenilikler kattığını ama bir yandan da beynimizin tembelleşmesine neden
        olduğunu belirtti.{" "}
      </p>
      <p>
        Aklımızı, mantığımızı, hafızamızı kullanmamız gereken pek çok işi
        teknoloji yoluyla yapmaya başladığımızı ifade eden Halıcı, az hareket
        eden bedenlerin karşılaştığı risklere benzer bir riskle beyinlerimizin
        karşı karşıya olduğunu kaydetti.
      </p>
      <p>
        Bu riskle mücadele etmenin yolunun teknoloji karşıtlığı olmaması
        gerektiğine dikkat çeken Halıcı, çözümün beynimizi daha çok çalıştırmak,
        daha çok düşünmek, daha çok merak etmek, daha çok soru sormak, daha çok
        araştırmak olduğunu dile getirdi.
      </p>
      <h5 style={{ fontWeight: "bold" }}>
        “Aklını ve zekâsını vicdanıyla birlikte kullanan insanların çoğalması
        dileğiyle...”
      </h5>
      <p>
        Halıcı, Şampiyona’ya katılan öğrencilerin geleceğin güvencesi olduğunu;
        aklını ve zekâsını vicdanıyla birlikte kullanan insanların çoğalmasını
        dilediğini belirterek derece alan tüm takımları tebrik etti.
      </p>
    </div>
  );
};
