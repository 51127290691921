import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Typography from "../../components/ui/typography";
import React, { useState } from "react";
import classNames from "./style.module.css";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const topButtonsValues = [
  { text: "BASIN BÜLTENİ", value: 0 },
  { text: "DERECE ALAN OKULLAR", value: 1 },
  { text: "FOTOĞRAF GALERİSİ", value: 2 },
];

export default () => {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const topButtons = (
    <ButtonGroup
      size="large"
      color="secondary"
      aria-label="large outlined primary button group"
    >
      {topButtonsValues.map((b) => {
        return (
          <Button
            onClick={() => setSelectedMenu(b.value)}
            key={b.value + "topButton"}
            style={{ color: "white", fontWeight: 200 }}
          >
            {b.text}
          </Button>
        );
      })}
    </ButtonGroup>
  );

  return (
    <div className={classNames.pageContainer}>
      {topButtons}
      {selectedMenu === 0 && <Bulten />}
      {selectedMenu === 1 && <MedahallOfFame />}
      {selectedMenu === 2 && <PhotoGallery />}
    </div>
  );
};

const MedahallOfFame = () => {
  const generateMedal = ({
    medalType,
    schoolLogoPath,
    schoolName,
    studentList,
    tutorList,
  }) => {
    const silverMedal = (
      <img src="/assets/images/medals/silver.png" alt="silver" />
    );
    const goldMedal = <img src="/assets/images/medals/gold.png" alt="gold" />;
    const bronzeMedal = (
      <img src="/assets/images/medals/bronze.png" alt="bronze" />
    );

    const medalImage =
      medalType === "gold"
        ? goldMedal
        : medalType === "silver"
        ? silverMedal
        : bronzeMedal;

    const schoolLogoImage = (
      <div>
        <img
          src={schoolLogoPath ? schoolLogoPath : "/assets/images/meb-logo.png"}
          alt="home-logo"
          width="120"
          height="120"
        />
      </div>
    );

    const schoolNameSpan = (
      <div
        style={{
          marginTop: 30,
          fontSize: "24px",
          fontWeight: "bolder",
          color: "#15273A",
          textTransform: "capitalize",
        }}
      >
        {schoolName.toLocaleLowerCase()}
      </div>
    );

    const studentListBox = (
      <div style={{ marginTop: 15 }}>
        <div style={{ fontSize: "24px", color: "#E79121" }}>Takım</div>
        {studentList.map((s) => (
          <div style={{ fontSize: "20px", textTransform: "capitalize" }}>
            {s.toLocaleLowerCase()}
          </div>
        ))}
      </div>
    );

    const tutorListBox = (
      <div style={{ marginTop: 15 }}>
        <div style={{ fontSize: "24px", color: "#E79121" }}>
          Sorumlu Öğretmen
        </div>
        {tutorList.map((t) => (
          <div style={{ fontSize: "20px", textTransform: "capitalize" }}>
            {t.toLocaleLowerCase()}
          </div>
        ))}
      </div>
    );
    return (
      <div
        style={{
          borderRadius: "15px",
          position: "relative",
          width: 339,
          height: 633,
          textAlign: "center",
          lineHeight: 1.1,
          margin: 10,
        }}
      >
        {medalImage}
        <div
          style={{
            position: "absolute",
            top: 150,
            textAlign: "center",
            width: 338,
            paddingLeft: 27,
            paddingRight: 27,
          }}
        >
          {schoolLogoImage}
          {schoolNameSpan}
          {studentListBox}
          {tutorListBox}
        </div>
      </div>
    );
  };

  const generateMedalGroup = ({ groupName, groupItems }) => {
    const header = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "42px",
          paddingBottom: 20,
          borderBottom: "solid #DA571A 2px",
          marginBottom: 20,
          lineHeight: 1,
        }}
      >
        <span style={{ color: "#DA571A", textAlign: "left" }}>
          Türkiye Finalleri Sonuçları
        </span>
        <span style={{ color: "#15273A", fontWeight: "bold", marginLeft: 30 }}>
          {groupName}
        </span>
      </div>
    );

    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 15,
          padding: 20,
          maxWidth: 1200,
          margin: 20,
        }}
      >
        {header}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {groupItems}
        </div>
      </div>
    );
  };

  //BİRİNCİ SINIF
  const birinciSinifMedals = generateMedalGroup({
    groupName: "Birinci Sınıf",
    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath: "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Ffatih_logo.jpg?alt=media&token=114dc5b2-2dfe-4c73-b6e3-3e910c343876",
        schoolName: "Fatih İlkokulu",
        studentList: ["Zeynep Sare Ergi", "İshak Susar", "Emre Mumcu"],
        tutorList: ["İlyas Erel"],
      }),
      generateMedal({
        medalType: "silver",
        schoolLogoPath: "",
        schoolName: "Bilge Kağan İlkokulu",
        studentList: ["Ali Kerem Tang", "Alp Ekinci", "Mehmet Eren Kılınç"],
        tutorList: ["Havva Baş"],
      }),
      generateMedal({
        medalType: "bronze",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fosman_o%CC%88zgu%CC%88r_io.jpg?alt=media&token=8606a9a5-b7ea-4715-b62b-82fd1c505c1a",
        schoolName: "Osman Özgür İlkokulu",
        studentList: [
          "Mehmet Kerem Kocadaş",
          "Nursel Azra Topuz",
          "Ada Yaren Gülhan",
        ],
        tutorList: ["Gülden Kocadaş"],
      }),
    ],
  });

  //İKİNCİ SINIF
  const ikinciSinifMedals = generateMedalGroup({
    groupName: "İkinci Sınıf",
    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Ferzurum_bilsem.jpeg?alt=media&token=09050d66-af1b-4cc3-9a63-f61d87416623",
        schoolName: "Erzurum Remzi Sakaoğlu Bilim Ve Sanat Merkezi",
        studentList: [
          "Hülya Çınar",
          "Göktürk Taha Kırkpınar",
          "Yusuf Alper Özdemir",
        ],
        tutorList: ["Nuran Paylan"],
      }),
      generateMedal({
        medalType: "silver",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fcanakkale_bilsem.jpeg?alt=media&token=21d149b3-bee5-4dbb-a6cb-8d8b1ffdc780",
        schoolName: "Çanakkale Bilim Ve Sanat Merkezi",
        studentList: ["Özgür Kaplan", "Elçin Nas Demirbaş", "Zeynep Berker"],
        tutorList: ["Ahmet Onur Çobanoğlu"],
      }),
      generateMedal({
        medalType: "bronze",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fistanbul_bilsem.jpeg?alt=media&token=411e29cf-8025-4607-8598-c54c5d5e274e",
        schoolName: "İstanbul Fuat Sezgin Bilim Ve Sanat Merkezi",
        studentList: ["Efe Günay", "Metin Aren Işık", "Can Bulucu"],
        tutorList: ["Berna Bengül Bursalıoğlu"],
      }),
    ],
  });

  //ÜÇÜNCÜ SINIF
  const ucuncuSinifMedals = generateMedalGroup({
    groupName: "Üçüncü Sınıf",

    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fsorgun_bilsem.jpeg?alt=media&token=20ec400a-09c5-4689-8e2b-a14ebe419346",
        schoolName: "Sorgun Bilim Ve Sanat Merkezi",
        studentList: ["Ömer Taş", "Deniz Aslan", "Talha Şenol"],
        tutorList: ["Mukaddes Ünal"],
      }),
      generateMedal({
        medalType: "silver",
        schoolLogoPath: "",
        schoolName: "Emlak Konut Ergene Vadisi İlkokulu",
        studentList: [
          "Ahmet Emir Üstün",
          "Ahmet Emir Bututaki",
          "Tunç Karacan",
        ],
        tutorList: ["Demet Karaboğa"],
      }),
      generateMedal({
        medalType: "bronze",
        schoolLogoPath: "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Ffatih_logo.jpg?alt=media&token=114dc5b2-2dfe-4c73-b6e3-3e910c343876",
        schoolName: "Fatih İlkokulu",
        studentList: ["Burak Saluç", "Emir Ege Arslanyörür"],
        tutorList: ["İlyas Erel"],
      }),
    ],
  });

  ///DÖRDÜNCÜ SINIF
  const dorduncuSinifMedals = generateMedalGroup({
    groupName: "Dördüncü Sınıf",
    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fmehmetcik_usak.jpeg?alt=media&token=11c5b625-140b-4a8b-a744-57b20f6f56a8",
        schoolName: "Mehmetcik İlkokulu",
        studentList: ["Halil Yiğit Pınar", "Elçin Dilivar", "Ömer Şahin"],
        tutorList: ["Döndü Şahin"],
      }),
      generateMedal({
        medalType: "silver",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fbornova_bilsem.jpeg?alt=media&token=c385f2d3-c59e-4b55-9ef6-baf8a75dff7d",
        schoolName: "Şehit Fatih Satır Bilim Ve Sanat Merkezi",
        studentList: ["Başar Dere", "Mehmet Erdemir", "Anıl Ege Karagül"],
        tutorList: ["Tuğba Çoşkun"],
      }),
      generateMedal({
        medalType: "bronze",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fulker_bilsem.jpeg?alt=media&token=07450d32-661e-4923-900d-7f1fe218dbc4",
        schoolName: "Ülker Yörükoğlu Bilim Ve Sanat Merkezi",
        studentList: [
          "Ali Kerem Aydın",
          "Sarp Zeki Kartallar",
          "Yağız Ege İlçioğlu",
        ],
        tutorList: ["Kadir Alper Çetin"],
      }),
    ],
  });

  //// BEŞİNCİ SINIF
  const besinciSinifMedals = generateMedalGroup({
    groupName: "Beşinci Sınıf",
    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fted_oo.png?alt=media&token=c6b547fa-443f-490c-a65a-1649b9075f54",
        schoolName: "Ted Ankara Koleji Vakfı Özel Ortaokulu",
        studentList: ["Kaan Ay", "Mehmet Toprak Karakaya", "Emre Macit"],
        tutorList: ["Fikriye Burçak Sayoğlu Köksal"],
      }),
      generateMedal({
        medalType: "silver",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fodtu_gvo.png?alt=media&token=62359534-aa64-4d7c-a6ba-802b47cf363d",
        schoolName: "Odtü Geliştirme Vakfı Özel Ortaokulu",
        studentList: ["Ahmet Can Baran", "Irmak Gençoğlu", "Cahit Alp Kunat"],
        tutorList: ["Pinar Ozkan"],
      }),
      generateMedal({
        medalType: "bronze",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fkadikoy_bilsem.jpeg?alt=media&token=52b914cf-3c4e-4931-998d-64fe1c098633",
        schoolName: "Kadıköy Bilim Ve Sanat Merkezi",
        studentList: [
          "Beyza Dağlıoğlu",
          "Can Ozan Özşahin",
          "Asya Mercan Özşahin",
        ],
        tutorList: ["Yudum Özer"],
      }),
    ],
  });

  //ALTINCI SINIF
  const altinciSinifMedals = generateMedalGroup({
    groupName: "Altıncı Sınıf",
    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fnesibe_oo.jpeg?alt=media&token=03353c76-6e7a-4223-935d-d8e41356d9e3",
        schoolName: "Özel Nesibe Aydın Yıldızlar Ortaokulu",
        studentList: ["Irmak Yıldız", "Nehir Yıldız", "Can Yeşiltepe"],
        tutorList: ["Tuğba Aldemir"],
      }),
      generateMedal({
        medalType: "silver",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fsumerler_oo.jpeg?alt=media&token=fcf50a2f-e40b-41cf-be20-38d02752b7ab",
        schoolName: "Sümerler Ortaokulu",
        studentList: ["Emir Oflazoğlu", "Cemre Su Sezen", "Kavin Bolat"],
        tutorList: ["Mine Kapı Duran"],
      }),
      generateMedal({
        medalType: "bronze",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fbahcesehir_koleji.png?alt=media&token=9f4d0db8-557f-47ab-8b17-5bea0a6e4591",
        schoolName: "Özel Karşıyaka Bahçeşehir Koleji",
        studentList: ["Yağız Aydın", "Suat Arhan Akçay", "Nehir Boz"],
        tutorList: ["Derya Aykut"],
      }),
    ],
  });

  //YEDİNCİ SINIF
  const yedinciSinifMedals = generateMedalGroup({
    groupName: "Yedinci Sınıf",
    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fkadikoy_bilsem.jpeg?alt=media&token=52b914cf-3c4e-4931-998d-64fe1c098633",
        schoolName: "Kadıköy Bilim Ve Sanat Merkezi",
        studentList: ["Serra İncekara", "Sinem Yazıcı", "Lidya Mira Koç"],
        tutorList: ["Yudum Özer"],
      }),
      generateMedal({
        medalType: "silver",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fkartal_bilsem.jpeg?alt=media&token=2e790fd1-cfb6-430f-8b59-4b596329230b",
        schoolName: "Kartal Bilim ve Sanat Merkezi",
        studentList: ["Güneş Banabak", "Emir Gedik", "Ahmed Selim Küçük"],
        tutorList: ["Umut Bektaş"],
      }),
      generateMedal({
        medalType: "bronze",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Frota_oo.png?alt=media&token=a239755f-1263-48df-af85-f3e09c825afc",
        schoolName: "Özel Rota Ortaokulu",
        studentList: ["Rüzgar Aras Yılmaz", "Beyza Kırkar", "Duru Gürel"],
        tutorList: ["Burcu Kocamaz"],
      }),
    ],
  });

  //SEKİZİNCİ SINIF
  const sekizinciSinifMedals = generateMedalGroup({
    groupName: "Sekizinci Sınıf",
    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fbahcesehir_koleji.png?alt=media&token=9f4d0db8-557f-47ab-8b17-5bea0a6e4591",
        schoolName: "Özel Bahçeşehir Ortaokulu",
        studentList: ["Lena Sarin Şahan", "Gökhan Deniz Atamer", "Defne Ergöl"],
        tutorList: ["Özge Çevik Tomar"],
      }),

      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fted_oo.png?alt=media&token=c6b547fa-443f-490c-a65a-1649b9075f54",
        schoolName: "Ted Ankara Koleji Vakfı Özel Ortaokulu",
        studentList: ["Batuhan Kaya", "Demir Tellioğlu", "Batu Peynircioğlu"],
        tutorList: ["Yasemin Yılmaz"],
      }),
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Faksehir_bilsem.jpeg?alt=media&token=b544e34a-4aa6-4607-829d-91e1fd25d81f",
        schoolName: "Akşehir Bilim Ve Sanat Merkezi",
        studentList: ["Osman Beyoğlu", "Ali Talha Öztürk"],
        tutorList: ["Nurcan Aldemir"],
      }),
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fsamsun_bilsem.jpeg?alt=media&token=208e9eb6-fa54-49af-9744-bbdc3c55f687",
        schoolName: "Samsun Bilim Ve Sanat Merkezi",
        studentList: ["Ahmet Hakan Bektaş", "Abdülkadir Pak"],
        tutorList: ["Sinem Kant"],
      }),
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fdonence_oo.jpeg?alt=media&token=eede4e41-638f-428c-9781-64b40369b0b5",
        schoolName: "Özel Dönence Ortaokulu",
        studentList: [
          "Halil Efe Zeybekci",
          "Zümra Kocakarın",
          "Deniz Demir Kartal",
        ],
        tutorList: ["Emine Yahşi"],
      }),
    ],
  });

  //DOKUZUNCU SINIF
  const dokuzuncuSinifMedals = generateMedalGroup({
    groupName: "Dokuzuncu Sınıf",
    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fistanbul_el.png?alt=media&token=619447ab-97b0-41a1-ba5b-592ccb81d44a",
        schoolName: "İstanbul Erkek Lisesi",
        studentList: [
          "Ayşe Melike Acar",
          "Eymen Arslan",
          "Dide Elif Yeşilırmak",
        ],
        tutorList: ["Murat Yoğurtçu"],
      }),
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fselcuklu_bilsem.jpeg?alt=media&token=54670cb3-08bc-4ca9-a90b-3816753a701b",
        schoolName: "Selçuklu Bilim Ve Sanat Merkezi",
        studentList: [
          "Arda Korkmaz",
          "Bedrettin Bera Kokulu",
          "Yavuz Mete Karagül",
        ],
        tutorList: ["Çağrı Kemal Erdal"],
      }),
      generateMedal({
        medalType: "bronze",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fgalatasaray_u%CC%88ni.png?alt=media&token=ddcda2ed-87f6-41e5-8140-cb66bed7861a",
        schoolName: "Galatasaray Üniversitesi Galatasaray Lisesi",
        studentList: ["Kenan Sari", "Onur Yılmaz", "Muhammet Burak Ünlü"],
        tutorList: [""],
      }),
    ],
  });

  //ONUNCU SINIF
  const onuncuSinifMedals = generateMedalGroup({
    groupName: "Onuncu Sınıf",
    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Feyupoglu_koleji.png?alt=media&token=92885699-e8ac-48e4-887f-2d21f51cf2ee",
        schoolName: "Özel Eyüboğlu Koleji",
        studentList: ["Melike Binici", "Kerem Suat Ertunç", "Güçlü Ada Dönmez"],
        tutorList: ["İsmet Baykal"],
      }),
      generateMedal({
        medalType: "silver",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Ferbak%C4%B1r_fenl.jpeg?alt=media&token=70dadb56-ed4f-481a-984c-ee216a73ee21",
        schoolName: "Erbakır Fen Lisesi",
        studentList: [
          "Halit Naci Kertik",
          "Türkan Demirel",
          "Fatma Nisa Bağcı",
        ],
        tutorList: ["Mustafa Akbulut"],
      }),
      generateMedal({
        medalType: "silver",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Ftu%CC%88bitak_fl.jpeg?alt=media&token=3f87371f-eb44-4461-9032-1340cbba3f24",
        schoolName: "Tübitak Fen Lisesi",
        studentList: [
          "Kadir Kağan Şahin",
          "Hüseyin Buğra Dündar",
          "Selman Ergin",
        ],
        tutorList: [""],
      }),
    ],
  });

  //ONBİRİNCİ SINIF
  const onbirinciSinifMedals = generateMedalGroup({
    groupName: "On Birinci Sınıf",
    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fkadikoy_bilsem.jpeg?alt=media&token=52b914cf-3c4e-4931-998d-64fe1c098633",
        schoolName: "Kadıköy Bilim Ve Sanat Merkezi",
        studentList: ["Beyza Ebrar Dilmaç", "Esma Kaya", "Bersu Berker"],
        tutorList: ["Yudum Özer"],
      }),
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fkartal_bilsem.jpeg?alt=media&token=2e790fd1-cfb6-430f-8b59-4b596329230b",
        schoolName: "Kartal Bilim Ve Sanat Merkezi",
        studentList: ["Tuna Aras Soysal", "Ceylin Duru Er", "Nehir Şentürk"],
        tutorList: ["Umut Bektaş"],
      }),
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fdenizli_gelisimkoleji.png?alt=media&token=1b380fef-8550-4171-9a25-06d23d851058",
        schoolName: "Özel Denizli Gelişim Koleji Fen Lisesi",
        studentList: ["Salih Şahin", "Ali Alp Yazıcı", "Cem Kaymanlı"],
        tutorList: [""],
      }),
    ],
  });

  //ONİKİNCİ SINIF
  const onikinciSinifMedals = generateMedalGroup({
    groupName: "On İkinci Sınıf",
    groupItems: [
      generateMedal({
        medalType: "gold",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fistanbul_el.png?alt=media&token=619447ab-97b0-41a1-ba5b-592ccb81d44a",
        schoolName: "İstanbul Erkek Lisesi",
        studentList: [
          "Sena Başaran",
          "Emir Batu Başıbüyük",
          "Utku Kıvanç Dündar",
        ],
        tutorList: ["Murat Yoğurtçu"],
      }),
      generateMedal({
        medalType: "silver",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Far%C4%B1_okullar%C4%B1.jpeg?alt=media&token=3bf77531-23d5-4923-8f88-4064eb566966",
        schoolName: "Özel Arı Anadolu Lisesi",
        studentList: ["Kutay Özbağcı", "Çınar Efe Çiftçi", "Begüm Hilal Şahan"],
        tutorList: ["Emrah Acer"],
      }),
      generateMedal({
        medalType: "bronze",
        schoolLogoPath:
          "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fderece_alan_okul_logolari%2Fnilu%CC%88fer_fl.jpeg?alt=media&token=facef2d6-a46e-4d06-9255-143a7b5e3e3b",
        schoolName: "Nılüfer Borsa İstanbul Fen Lısesı",
        studentList: ["Ahmet Servet Çağan", "Talha Colfaoğlu", "Bora Ünlü"],
        tutorList: ["İpek Teymuroğlu"],
      }),
    ],
  });
  return (
    <div>
      {birinciSinifMedals}
      {ikinciSinifMedals}
      {ucuncuSinifMedals}
      {dorduncuSinifMedals}
      {besinciSinifMedals}
      {altinciSinifMedals}
      {yedinciSinifMedals}
      {sekizinciSinifMedals}
      {dokuzuncuSinifMedals}
      {onuncuSinifMedals}
      {onbirinciSinifMedals}
      {onikinciSinifMedals}
    </div>
  );
};

const PhotoGallery = () => {
  return (
    <div style={{ marginTop: "30px" }}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://halici.pixieset.com/sampiyona2023-24/"
      >
        <Button size="large" color="secondary" variant="contained">
          GALERİYE ULAŞMAK İÇİN TIKLAYINIZ
        </Button>
      </a>
    </div>
  );
};

const Bulten = () => {
  return (
    <div
      className={"card " + classNames.long_description}
      // className={"card"}
      style={{ fontSize: "medium" }}
    >
      <h4 style={{ fontWeight: "bold", marginTop: 30, textAlign: "center" }}>
        Türkiye 5. Okullar Arası Zeka Oyunları Şampiyonası’nın Ödül Töreni MEB
        Şura Salonuna Gerçekleştirildi!
      </h4>
      <img
        style={{ width: "100%" }}
        src="https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fbulten_foto%2F01A.jpg?alt=media&token=97cd7329-6825-4b37-9f3e-31e541190dbe"
        alt="ödül töreni fotoğrafı"
      ></img>
      {/* <div
        className={"card " + classNames.sponsor_card}
        style={{ marginTop: 0 }}
      >
        <iframe
          width="560"
          maxWidth="100%"
          height="315"
          // maxHeight = "100%"
          src="https://www.youtube.com/embed/wX1SGCKw-qo"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div> */}
      <p>
        Türkiye Zeka Vakfının Millî Eğitim Bakanlığı ile TÜBİTAK’ın
        destekleriyle başlattığı "Türkiye Okullar Arası Zeka Oyunları
        Şampiyonası"nın bu seneki ödül töreni MEB Şura Salonu’nda
        gerçekleştirildi.
      </p>
      <p>
        Türkiye'nin dört bir yanından gelen öğretmen ve öğrencilerin katılım
        sağladığı Şampiyona'da derece elde eden gençlerin heyecanı tüm salonda
        hissedildi.
      </p>
      <p>
        Ödül töreninde, farklı kategorilerde dereceye giren okullara ödülleri
        takdim edildi. Derece alan okullar arasında İstanbul, Ankara, İzmir gibi
        büyük şehirlerden ve birçok farklı bölgeden okullar bulunuyordu.
      </p>

      <p>
        Türkiye Zeka Vakfı Başkanı Emrehan Halıcı, konuşmasında, aldığımız
        kararlarda beynimizin yönlendirici rolüne dikkat çekti. Daha sağlıklı
        kararlar alabilmek için sağlıklı ve iyi düşünen beyinlere ihtiyacımız
        olduğunu vurguladı. Beden sporları kadar beyin sporlarının da önemli
        olduğunu belirten Halıcı, Türkiye Zeka Vakfı olarak 30 yıldır bu amaçla
        çeşitli etkinlikler düzenlediklerini ifade etti. Tüm aşamaları geçerek
        başarılarını kanıtlayan öğrencilerin, bilimde, teknolojide, sanatta ve
        hayatta büyük başarılara imza atacaklarını söyledi. Ayrıca, öğrencilere
        verecekleri kararlarda sadece beyinlerini değil, kalplerini ve
        vicdanlarını da dinlemelerini ve tüm insanlığın iyiliğini ön planda
        tutmalarını tavsiye etti. Zeka ve ahlakın bir arada olduğunda anlam
        kazandığını, dünyadaki sorunların azalmasının zeki, çalışkan, ahlaki
        değerlere önem veren, doğayı ve tüm canlıları seven insanların
        çoğalmasıyla gerçekleşebileceğini dile getirdi. Derece elde eden tüm
        takımları kutlayarak, desteklerinden dolayı Milli Eğitim Bakanlığı,
        TÜBİTAK ve ODTÜ’ye teşekkürlerini iletti.
      </p>

      <img
        style={{ width: "100%" }}
        src="https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fbulten_foto%2FAsset%209%402x.png?alt=media&token=de0b68ee-ee8c-41c4-8584-44b01c1d1463"
        alt="saygı duruşu fotoğrafı"
      ></img>

      <p>
        Törende konuşma yapan Ankara Üniversitesi Rektörü Prof. Dr. Necdet
        Ünüvar konuşmasında temel değerlere uyumun öneminden bahsederek,
        başarıda odaklanmanın önemini dile getirdi. Ünüvar, odaklanamayan
        yetenekli insanların başarılı olamadıklarını belirtti. Derece elde eden
        yarışmacıları, onlara rehberlik eden öğretmenleri ve anne babalarını
        tebrik ederek konuşmasını sonlandırdı.
      </p>

      <img
        style={{ width: "100%" }}
        src="https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fbulten_foto%2FAsset%2010%402x.png?alt=media&token=8fcf7f73-9254-400e-82f1-573dec25ada0"
        alt="kupa teslimi"
      ></img>

      <p>
        TÜBİTAK Başkanı Prof. Dr. Hasan Mandal, konuşmasında 5’incisi
        gerçekleşen ödül törenine katılmaktan duyduğu memnuniyeti dile getirdi.
        1.sınıftan 12. Sınıfa kadar öğrencilerin katıldığı Şampiyona’da
        Türkiye'nin her bir yerinden keşfedilmeyi bekleyen öğrencilere
        ulaşıldığını kaydeden Mandal, öğrencileri yürekten tebrik ettiğini
        belirtti. Gençlerimizin yapabileceklerini gördüklerinde geleceğe daha
        umutla baktıklarına işaret eden Mandal, “Haftaya ortaokul ve lise proje
        öğrencilerimizin araştırma projelerinin yarışma finali var. Türkiye Zeka
        Vakfı gibi, aynı yöntemle çalışıyoruz. Önce bölgelerde, önce bölgeye
        aday oluyorlar, daha sonra bölgede yarışıyorlar, sonrasında da ulusal
        finale geliyorlar. Takım halinde olabilmek önemli.” dedi. Mandal,
        ülkemizin ve dünyanın en önemli ihtiyacının nitelikli, ahlaklı ve
        yetenekli insan kaynağı olduğunu vurguladı. Dereceye giren tüm
        öğrencileri tebrik ederek, salonda bulunan bu başarılı gençlerin
        geleceğimiz olduğunu belirtti. TÜBİTAK olarak gençleri desteklemeye
        devam edeceklerini ifade etti.
      </p>

      <img
        style={{ width: "100%" }}
        src="https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Fbulten_foto%2FAsset%2011%402x.png?alt=media&token=a59ed16d-52b4-4235-a4cd-834f064c8919"
        alt="Tübitak Başkanı"
      ></img>

      <p>
        Milli Eğitim Bakan Yardımcısı Dr. Ömer Faruk Yelkenci konuşmasında, zekâ
        ve yeteneğin birleşerek tüm insanlığa hizmet etmesi gerektiğini
        vurguladı. "Bu dünyayı daha iyi bir yer haline getirmek için zekâmızı
        kullanırken, ahlak ve vicdan rehberliğinde hareket ettiğimizde,
        aldığımız her karar sadece bizi değil, çevremizi ve dünyayı da olumlu
        yönde etkileyecektir. Kararlarınızı alırken kalbimizin ve vicdanımızın
        rehberliğine başvurmak, bizi sadece başarılı değil, aynı zamanda iyi bir
        insan yapacaktır." dedi. Bu amaç doğrultusunda düzenlenen Şampiyona'nın
        önemine değinen Dr. Yelkenci, etkinliğin gerçekleşmesinde emeği geçen
        herkese teşekkürlerini iletti. Derece elde eden tüm takımları ve
        öğretmenlerini tebrik eden Yelkenci, bu tür etkinliklerin öğrencilerin
        gelişimine büyük katkı sağladığını ve ülkemizin geleceği için umut
        verici olduğunu söyledi.
      </p>
    </div>
  );
};
