import React from "react";
import classNames from "../manage.module.css";

export const paymentInstructionsBox = () => {
  return (
    <div
      style={{ color: "white", fontSize: "1.3rem" }}
      className={"card " + classNames.left_step}
    >
      <div>Kayıt/Ödeme süreci sona ermiştir. </div>
      {/* {!user.class_id && (
        <div>
          10 Şubat'taki asıl yarışmaya katılabilmek için lütfen ödeme yapınız.
          (Ödeme yapabilmek için önce sınıf ve okul bilgilerinizi
          güncelleyiniz.)
        </div>
      )} */}
      {/* {user.class_id && (
        <div>
          10 Şubat'taki asıl yarışmaya katılabilmek için lütfen ödeme yapınız.
        </div>
      )} */}
      {/* {user.class_id && (
        <Link to="/satinal">
          <Button
            background="orange"
            style={{ width: 140, height: 50, fontSize: "1.3rem" }}
          >
            Ödeme Yap
            <LinearProgress color="secondary" />
          </Button>
        </Link>
      )} */}
    </div>
  );
};
