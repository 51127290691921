import React from "react";
import classNames from "./help.module.css";
import Typography from "../../components/ui/typography";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Collapsable from "../../components/ui/collapsable/collapsable";

const sss = [
  {
    title: "Şampiyonaya kimler katılabilir?",
    body: "Türkiye genelinde faaliyet gösteren devlet okulları, özel okullar, Bilim ve Sanat Merkezleri katılım sağlayabilir. Ayrıca Milli Eğitim Bakanlığına bağlı olarak yurtdışında faaliyet gösteren okullar da ayrı bir bölge olarak değerlendirilmek üzere Şampiyona'ya katılabilir.",
  },
  // {
  //   title: "Şampiyona için ödeme yaptım ama ödeme sistemde görünmüyor?",
  //   body:
  //     <span>Ödemenizin tekrar kontrol edilmesi için  <a href="https://sampiyona.tzv.org.tr/payment_result" target="_self"> tıklayınız. </a></span>,
  // },

  {
    title: "Şampiyonaya katılım nasıl gerçekleştirilir?",
    body: "Dileyen her öğrenci bireysel olarak Şampiyona’ya kayıt olabilir ve İl Finalleri Sınavında okulu adına yarışabilir.",
  },
  {
    title: "Şampiyonanın kayıt işlemleri nasıl yapılmaktadır?",
    body: "Öğrenci ve takım sorumlusu öğretmenler Şampiyonaya bireysel olarak kayıt yaparlar. Kayıt esnasında okul ve sınıf bilgisini seçilir.",
  },
  {
    title: "İl Finalleri sınavına okullardan kaç öğrenci katılabilir?",
    body: "İl Finalleri Sınavına tüm okullardan dileyen her öğrenci katılabilir. Katılacak öğrenci sayısı için bir üst sınır yoktur. Bu sınavda herkes bireysel olarak yarışır.",
  },
  {
    title:
      "Şampiyonada takımlar kaç kişiden oluşmaktadır? Takım üyeleri neye göre belirlenmektedir?",
    body: "İl Finalleri Sınavında kendi okulunun ilgili sınıfında ilk 3 sırayı alan öğrenciler okulun o sınıftaki takımını oluşturmaktadır. Takımlar 1 ya da 2 öğrenciyle de oluşabilir. Eşitlik durumunda İl Finalleri Sınavını önce bitiren öğrenci üst sırada yer alır. Sınavı bitirme süresi olarak en son cevap kaydı yapılan zaman dikkate alınır. ",
  },
  {
    title: "Okullar, şampiyonaya en fazla kaç sınıf ile katılım sağlayabilir?",
    body: "Okullar uygun gördükleri tüm sınıflarda şampiyonaya katılabilir.",
  },

  {
    title: "Şampiyonaya katılım ücreti ne kadar? ",
    body: "Katılım ücreti her öğrenci için 180 TL’dir.  Kayıt işlemi tamamlandıktan sonra ücret ödeme ekranı açılacaktır. Önce kayıt olup ödeme daha sonra da yapılabilir. Kayıt ücreti dışında sınavlar için başka bir ücret alınmayacaktır.",
  },
  {
    title: "Okullar öğrencilerin katılım ücretini toplu olarak yatırabilir mi?",
    body: "Evet. Konuyla ilgili yönlendirme için sampiyona@tzv.org.tr adresine mail gönderebilirsiniz.",
  },
  {
    title: "Takım sorumlusu öğretmenlerin Şampiyonadaki rolü nedir?",
    body: "Takım sorumlusu öğretmenler kendi okullarında sorumlu oldukları sınıftan Şampiyonaya kayıt olan öğrencilerin listesini görebilir, sınav sonuçlarına erişebilir, Şampiyona sürecinde sınavlarla ilgili bilgilendirme e-postalarını alabilir. Sınavlardan önce öğrencilerin organize edilmesi, hatalı bilgilerin düzeltilmesi, takım üye değişim işlemlerinin yapılması vb. konularda koordinasyonu sağlayarak gerektiğinde TZV ile iletişime geçebilir. Bir okulda bir sınıf için en fazla 1 takım sorumlusu öğretmen olabilir; ancak bir öğretmen kendi okulundaki birden fazla sınıfın takım sorumlusu olabilir. Şampiyonanın her aşamasında takımın hak kazandığı elektronik katılım/başarı belgeleri takım sorumlusu öğretmenlere de verilir.",
  },
  {
    title:
      "Kayıt esnasında bilgilerimi hatalı girdiğimi farkettim. Nasıl düzeltebilirim?",
    body: "Okul, sınıf, ad, soyad vb. bilgilerinizi Şampiyona profil sayfanızdaki bilgi güncelleme ekranından düzeltebilirsiniz. Profil güncelleme ekranı İl Finalleri Sınavı başlayana kadar kullanılabilir olacaktır. Hatalı okul/sınıf seçimi, yanlış kategoride soru görmenize ya da takımların hatalı sıralanmasına sebep olabileceği için İl Finalleri Sınavı başladıktan sonra sınav sonucunuza etki edebilecek değişiklik talepleri diskalifiye edilmenizle sonuçlanabilir. İl Finalleri Sınavından sonra hatalı bilgi tespit ederseniz iletişim e-posta adreslerimiz üzerinden bize ulaşınız.",
  },
  {
    title: "Şampiyonanın İl Finalleri nerede ve kaç dakika olacak?",
    body: "İl Finalleri yarışma takviminde belirtilen tarihte Şampiyona internet sayfası üzerinden yapılacaktır. Takvimde belirtilen süre aralığında cevapların belirtilen forma girilmesi ve kaydedilmesi gerekmektedir.",
  },
  {
    title: "Ek Soruların cevaplanması zorunlu mudur?",
    body: "Ek soruların cevaplanması zorunlu değildir. Herhangi iki yarışmacının ya da takımın asıl sorulardan aynı puanı alması halinde bu yarışmacıların/takımların sıralanması esnasında ek sorular dikkate alınacaktır. İl Finalleri Sınavında hem asıl hem ek sorulardan tam olarak aynı puanı alan yarışmacılar sınavı bitirme sürelerine göre sıralanır. Bölge Finallerinde eşitlik olması durumunda İl Finalleri sıralaması, Türkiye Finallerinde eşitlik olması durumunda Bölge Finalleri sıralaması dikkate alınacaktır.",
  },
  {
    title: "Sınavda yanlış cevaplar doğru cevapları götürüyor mu?",
    body: "Yanlış cevapların puana etkisi yoktur. (Yanlış doğruyu götürmez.) Bir soruya hatalı cevap vermek ile o soruyu boş bırakmak arasında fark olmayacaktır.",
  },
  {
    title: "Sınavın herhangi bir aşamasında öğrenciler yardım alabilir mi?",
    body: "Öğrenciler İl Finalleri Sınavında bireysel olarak yarışırlar. Bölge Finalleri ve Türkiye Finalleri aşamasında ise sorular takım halinde çözülecektir. Bölge Finallerinde sorular takım üyelerinin ekranına gelir, bir yarışmacının verdiği cevabı diğer takım arkadaşları anlık olarak görebilir. Türkiye Finalleri Sınavı yazılı yapılacağı için takım üyeleri aynı masada aynı soru kitapçığı üzerinde tartışarak cevap formunu dolduracaktır. Yarışmacılar hesap makinesi vb. yardımcı cihazlar kullanamazlar. Öğretmenler öğrencilere yardım edemezler.",
  },
  {
    title: "Sınavlara ait sonuçlar ne zaman / nerede yayınlanacak?",
    body: "Tüm sonuçlar Şampiyona internet sayfasında, takvimde belirtilen tarihlerde ilan edilecektir. Şampiyonaya katılan tüm okulların puanları ve sıralamaları herkesin erişimine açık olacak, itiraz süreci içerisinde yapılan tüm itirazlar değerlendirmeye alınacaktır.",
  },
  {
    title:
      "Bölge Finalleri Sınavına katılmaya hak kazanan okullar nasıl belirlenecek?",
    body: "Bölge Finalleri Sınavına her ilde İl Finalleri Sınavında ilk 5 sırayı alan okullar ve ek olarak İl Finalleri Sınavında ilk %30’luk dilime giren okullar katılır. 0 paun alan takımlar ilk 5'te yer alsa bile Bölge Finallerine katılamaz.",
  },
  {
    title:
      "Türkiye Finalleri Sınavına katılmaya hak kazanan okullar nasıl belirlenecek?",
    body: "Türkiye Finalleri Sınavına ülkemizin 7 coğrafi bölgesi dikkate alınarak her bölgedeki Bölge Finalleri Sınavında ilk 3 sırayı alan 21 okul ve bu okullar dışında Bölge Finalleri Sınavında ilk sırayı alan 29 okul (toplam 50 okul) katılır. 0 paun alan takımlar ilk 3'te yer alsa bile Türkiye Finallerine katılamaz. (Yurt dışında faaliyet gösteren okullardan katılım varsa bu okullar ayrı bir bölge olarak değerlendirilerek sınava katılabilir.)",
  },
  {
    title: "Şampiyonanın Türkiye Finalleri Sınavı nerede olacak?",
    body: "Şampiyonanın Türkiye Finalleri Sınavı Ankara'da yazılı olarak yapılacaktır. Sınava ait salon bilgisi ilerleyen günlerde Şampiyona internet sayfası üzerinden ilan edilecektir. (Pandemi koşulları gibi aksi bir durum söz konusu olması halinde TZV tarafından farklı bir karar alınabilir.)",
  },
  {
    title: "Şampiyona sonunda takımlar nasıl ödüllendirilecek?",
    body: (
      <ul className={classNames.list}>
        <li>
          Her sınıfta ilk üç dereceye giren takımlara kupa, takım üyesi
          öğrencilerine ve sorumlu öğretmenlerine madalya verilir. Ayrıca bu
          okullara çeşitli kitap ödülleri de verilecektir.
        </li>
        <li>
          Her sınıfta ilk üç dereceye giren takımların öğrencileri Şampiyona
          sonunda internet üzerinden yapılacak olan Türkiye Zeka Vakfı “Düşünce
          Şenliği”ne katılım hakkı kazanır.
        </li>
        {/* <li>
          Türkiye Finalleri Sınavı’nda yarışan tüm öğrenci ve öğretmenlere
          GriCeviz Bilişsel Beceri ve Zihinsel Gelişim Platformu için 1 yıllık
          aile üyeliği ödül olarak verilir.
        </li> */}

        <li>
          Ayrıca;
          <ul>
            <li>
              İl Finalleri Sınavı’nda Türkiye geneli sıralamada ilk %30’luk
              dilime giren tüm yarışmacılara elektronik İl Finalleri Başarı
              Belgesi, diğer katılımcılara elektronik İl Finalleri Katılım
              Belgesi verilir,
            </li>
            <li>
              Bölge Finalleri Sınavı’nda sonraki aşamaya katılım hakkı kazanan
              tüm takım üyelerine elektronik Bölge Finalleri Başarı Belgesi,
              diğer katılımcılara elektronik Bölge Finalleri Katılım Belgesi
              verilir,
            </li>
            <li>
              Türkiye Finalleri Sınavı’nda dereceye giren tüm takım üyelerine
              elektronik Türkiye Finalleri Başarı Belgesi, diğer katılımcılara
              elektronik Türkiye Finalleri Katılım Belgesi verilir.
            </li>
          </ul>
        </li>

        <li>
          Millî Eğitim Bakanlığına bağlı olarak yurt dışında faaliyet gösteren
          okullardan Şampiyona'ya katılım olması halinde ilgili ülke ayrı bir
          bölge olarak değerlendirilecektir. Türkiye Finalleri Sınavı sonucuna
          göre söz konusu okulların derecelendirilmesi ve ödüllendirilmesi
          konusunda TZV tarafından ayrıca karar alınacaktır.
        </li>
      </ul>
    ),
  },
  {
    title: "Şampiyonanın geçen seneki belgelerine nasıl ulaşabilirim?",
    body: (
      <p>
        Şampiyonada geçen seneki belgelerine ulaşmak isteyenler{" "}
        <a target="_blank" href="https://championship-2022-23.web.app/">
          championship-2022-23.web.app 
        </a> 
        {" "}adresinden şampiyona sayfası giriş bilgilerini kullanarak giriş
        yapıp geçen seneki tüm belgelerini indirebilirler.
      </p>
    ),
  },
];

const Help = () => (
  <div className={"card " + classNames.help}>
    <Typography font="merel" size="normal" weight="bold" color="orange">
      Yardım
    </Typography>
    <Typography
      font="tadao"
      weight="normal"
      color="white"
      size="smaller"
      style={{ paddingBottom: 30 }}
    >
      Lütfen iletişime geçmeden önce{" "}
      <span className="orange">sık sorulan soruları </span>inceleyiniz.
    </Typography>
    {sss.map(({ body, title }, key) => (
      <Collapsable key={key} title={title} body={body} />
    ))}
    <ScrollToTop />
  </div>
);

export default Help;
