import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../ui/button";
import { mailStats as mailStatsAction } from "../../store/actions/participants";
import classNames from "./footer.module.css";
import NavLinks from "../navlinks/navlinks";
import Typography from "../ui/typography";
const Footer = ({ ...props }) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const mailStats = useCallback(() => dispatch(mailStatsAction()), [dispatch]);
  return (
    <footer className={classNames.footer}>
      <div className={classNames.container}>
        <img
          className={classNames.footer_image}
          src="/assets/images/ill.png"
          alt="ill-pair"
        />
        <img
          className={classNames.logo}
          src="/assets/images/tzv-logo-white.png"
          alt="Logo white"
        />
        <div className={classNames.navlinks}>
          <NavLinks color="grey" footer />
          <a
            href="https://www.tzv.org.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography size="smallest" color="orange">
              www.tzv.org.tr
            </Typography>
          </a>
        </div>
        <Typography
          size="smallest"
          weight="light"
          color="grey"
          className={classNames.copyright}
        >
          Tasarım ve Geliştirme Halıcı Bilgi İşlem A.Ş.{" "}
          <a
            className="orange"
            href="https://www.halici.com.tr"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.halici.com.tr
          </a>
        </Typography>
        {user && user.role == 127 && (
          <Button
            background="orange"
            color="grey"
            onClick={mailStats}
            style={{
              background: "transparent",
              borderWidth: 0,
              fontSize: "smaller",
            }}
          >
            Detaylı İstatistikleri Mail Olarak Al{" "}
          </Button>
        )}
      </div>
    </footer>
  );
};

export default Footer;
