import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "./home.module.css";
import {
  Element,
  Events,
  scrollSpy,
  scroller,
  Link,
  animateScroll,
} from "react-scroll";
import Landing from "./sections/landing";
import Updates from "./sections/updates";
import About from "./sections/about";
import Schedule from "./sections/schedule";
import Conditions from "./sections/conditions";
import Sponsors from "./sections/sponsors";

import LastYearResults from "./sections/lastYearResults";

import BottomSignUp from "./sections/bottomSignUp";
import FloatingMenu from "../../components/floatingMenu/floatingMenu";
import Typography from "../../components/ui/typography";
import Steps from "../steps/steps";

const Home = ({ history }) => {
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function (to, element) {
      // console.log("end", arguments);
    });

    scrollSpy.update();
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const path = useSelector((state) => state.router.location.pathname);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const config = {
      duration: 1500,
      delay: 100,
      smooth: "easeInOutQuint",
    };
    history.location.state &&
      history.location.state.scroll &&
      scroller.scrollTo(path, config);
  }, [path, history]);
  const navigate = (el) =>
    (history.location.state && history.location.state.scroll) ||
    history.push(el, { manuel: true });

  const bakanMesaji = (
    <div className={classNames.sponsors} style={{ maxWidth: 786 }}>
      <Typography size="big" color="orange" font="merel">
        Türkiye Okullar Arası Zeka Oyunları Şampiyonası 2020-2021 Final
        Sonuçları
      </Typography>

      <div className={"card " + classNames.sponsor_card}>
        <iframe
          width="840"
          height="470"
          src="https://www.youtube.com/embed/moMy0SyqKWQ"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );

  const yarismaBanner = (
    <div className={classNames.sponsors} style={{ maxWidth: 786 }}>
      <a //target="_blank"
        href="/updates"
      >
        <div className={"card " + classNames.sponsor_card}>
          <img
            style={{ width: "100%", borderRadius: 15 }}
            src="assets/images/sonuc-banner.png"
            alt="oyun_banner"
          />
        </div>
      </a>
    </div>
  );

  return (
    <div className={classNames.home}>
      <Element name="/landing">
        <Landing />
      </Element>
      {/* <LastYearResults/> */}
      {/* {yarismaBanner} */}
      {/* {bakanMesaji} */}
      {/* <Sponsors /> */}
      <Updates />
      <Element name="/hakkinda">
        <About />
      </Element>
      <Element name="/takvim">
        <Schedule />
      </Element>
      <Element name="/kosullar">
        <Steps hideAboutSection={true} />
      </Element>
      {!user && <BottomSignUp />}
      <div style={{ display: "none" }}>
        <Link to="/hakkinda" spy onSetActive={navigate} />
        <Link to="/takvim" spy onSetActive={navigate} />
        {/* <Link to="/kosullar" spy onSetActive={navigate} /> */}
        <Link to="/" spy onSetActive={navigate} />
      </div>
      <FloatingMenu onClick={animateScroll.scrollToTop} />
    </div>
  );
};

export default Home;
