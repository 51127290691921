import React, { useState, useEffect } from "react";
import Typography from "../../../components/ui/typography";
import PdfExporter from "../../../components/pdfcreate/pdfExporter";
import { IF_RESULT_TEXTS, PDF_DOC_PROPS } from "../../../common/constants";
import { detailedUpdatesLink, personalExamLink } from "./generateLinks";
import classNames from "../manage.module.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "../../../components/ui/button";
import Box from "@material-ui/core/Box";
import PdfExporterSEBF_IF from "../../../components/pdfcreate/1_il_finalleri/pdfExporterSEBF_IF";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { SET_SELECTED_STUDENTS_FOR_MULTIPLE_PAYMENT } from "../../../store/actionTypes";

export const IfSection = ({
  user,
  didStudentPay,
  studentList,
  users_of_this_school,
}) => {
  // Her okuldan ilk 6'ya giren ve 0'dan yüksek puanı olan herkese il finali başarı belgesi veriyoruz.

  // const willUserTakeSEBF =
  //   ifResult && // if'ten elenen, diskalifiye olanlara form verelim. Ayrıca if'te asil/yedek olup da bölge finalerine katılmayana da verelim.
  //   ([3, 4].includes(ifResult.conclusion) ||
  //     ([1, 2].includes(ifResult.conclusion) && !bfResult));

  // // const willUserTakeSEBF_later = ifResult && [1, 2].includes(ifResult.conclusion);// asil ve yedekler formlarını sonraki aşamada alacaklar.

  // const il_fin_conclusion = ifResult && ifResult.conclusion;

  // const il_fin_conclusion_long_text =
  //   IF_RESULT_TEXTS[il_fin_conclusion]?.long;

  const [params, setParams] = useState({
    anyCoachUserAttendedToIF: false,
    anySchoolUserAttendedToIF: false,
    anyStudentOfThisCoachTakesIFsuccessDoc: false,
    anyStudentOfThisSchoolTakesIFsuccessDoc: false,
  });

  const [redirectPage, setRedirectPage] = useState("");
  const [selectedStudents, setSelectedStudents] = useState({});

  useEffect(() => {
    // Exam results geldiğinde öğrenci listesi üzerinden 1 tur geçip bu okuldan hangi sınavlara katılım olduğunu bulalım:
    const schoolResults = studentList; // 1-3
    const allStudentsOfThisSchool = users_of_this_school; // 5

    if (user?.role === 1 && allStudentsOfThisSchool) {
      let anyCoachUserAttendedToIF = false;
      let anySchoolUserAttendedToIF = false;
      let anyStudentOfThisCoachTakesIFsuccessDoc = false;
      let anyStudentOfThisSchoolTakesIFsuccessDoc = false;

      for (let i = 0; i < schoolResults.length; i++) {
        // sadece bu öğretmenin öğrencilerini kontrol eder:
        if (schoolResults[i].willUserTakeIFBasariBelgesi === true) {
          anyCoachUserAttendedToIF = true;
          anyStudentOfThisCoachTakesIFsuccessDoc = true;
        }

        if (schoolResults[i].willUserTakeIFKatilimBelgesi === true) {
          anyCoachUserAttendedToIF = true;
        }
      }

      for (let x = 0; x < allStudentsOfThisSchool.length; x++) {
        if (allStudentsOfThisSchool[x].willUserTakeIFBasariBelgesi === true) {
          anySchoolUserAttendedToIF = true;
          anyStudentOfThisSchoolTakesIFsuccessDoc = true;
        }

        if (allStudentsOfThisSchool[x].willUserTakeIFKatilimBelgesi === true) {
          anySchoolUserAttendedToIF = true;
        }
      }

      setParams({
        ...params,
        anyCoachUserAttendedToIF,
        anySchoolUserAttendedToIF,
        anyStudentOfThisCoachTakesIFsuccessDoc,
        anyStudentOfThisSchoolTakesIFsuccessDoc,
      });
    }
  }, [studentList, users_of_this_school]);
  const dispatch = useDispatch();

  if (!didStudentPay && user?.role === 0) return null;

  const onPurchaseClickedForSelectedStudents = () => {
    // TODO: Bunu ifSection içine taşı
    dispatch({
      type: SET_SELECTED_STUDENTS_FOR_MULTIPLE_PAYMENT,
      payload: Object.keys(selectedStudents),
    });
    setRedirectPage("/satinal");
  };

  const willCoachTakeSuccessDoc = params.anyStudentOfThisCoachTakesIFsuccessDoc;
  const willCoachTakeAttendanceDoc =
    !willCoachTakeSuccessDoc && params.anyCoachUserAttendedToIF;

  const willSchoolTakeSuccessDoc =
    params.anyStudentOfThisSchoolTakesIFsuccessDoc;
  const willSchoolTakeAttendanceDoc =
    !willSchoolTakeSuccessDoc && params.anySchoolUserAttendedToIF;

  const studentTableIF = (
    <Box sx={{ overflow: "auto", marginTop: 20 }}>
      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
        <TableContainer component={Paper}>
          <Table aria-label="a dense table">
            <TableHead>
              <TableRow>
                {[
                  { name: "ID", align: "center" },
                  { name: "Ad-Soyad", align: "left" },
                  // { name: "E-Posta", align: "left" },
                  { name: "Sınıf", align: "center", hideForThisRole: 0 },
                  // { name: "Ücret Durumu", align: "center", hideForThisRole: 0 },
                  // { name: "Seç", align: "center", hideForThisRole: 0 },
                  { name: "Puanı", align: "center" },
                  { name: "TR Sırası", align: "center" },
                  { name: "Bölge Finallerine Katılım Durumu", align: "center" },

                  { name: "Sınav Linki", align: "center", hideForThisRole: 0 },
                  { name: "Katılım/Başarı Belgesi", align: "center" },
                  { name: "Sosyal Etkinlik Formu", align: "center" },

                  // { name: "Belge İndir", align: "center" },
                  // { name: "Sosyal Etkinlik Formu", align: "center" },
                  // { name: "Bölge Finalleri Sonucu", align: "center" },
                  // { name: "Türkiye Finallerine Katılım", align: "center" },
                  // { name: "İl Finali Puanı", align: "center" },
                  // { name: "Bölge Finaline Katılım Durumu", align: "center" },
                ].map(
                  (row) =>
                    row.hideForThisRole !== user.role && (
                      <TableCell
                        key={row.name + "_user_list_column_name"}
                        style={{ fontWeight: "bold" }}
                        align={row.align}
                      >
                        {row.name}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {studentList.map((row) => {
                if (!row.is_paid) return null;

                const if_2024_result =
                  row?.exam_results.find((r) => r.season === "2024_if") ?? {};

                // Öğrencilerden, bf katılacaklar sadece kendi takım arkadaşlarını görecek. Elenenler ise başarılı olan 3 kişiyi ve kendini listede görecek.
                // İF sonuçları ilan edildikten sonra, BF süreci başlayana kadar bu if'i commentleyebilirsin. Herkes herkesi görür o zaman.
                if (
                  user.role === 0 &&
                  ![1, 3].includes(if_2024_result.conclusion) &&
                  user.id !== row.user_id
                )
                  return null;

                const generateIfSEBF = () => {
                  if (row.willUserTakeIF_SEBF)
                    return (
                      <PdfExporterSEBF_IF
                        size="small"
                        type={PDF_DOC_PROPS.if_sebf.type}
                        school={row.school_name}
                        name={row.name}
                        fileNumber={"TZVSMP23 il/" + row.user_id}
                      />
                    );
                  else if (
                    row.willUserTakeIFBasariBelgesi ||
                    row.willUserTakeIFKatilimBelgesi
                  ) {
                    return (
                      <Button
                        style={{
                          background: "green",
                          fontSize: 12,
                        }}
                        onClick={() => {
                          alert(
                            "Bu yarışmacıya ait Sosyal Etkinlik Bilgilendirme Formu ileriki sınavların sonucuna göre hazırlanacaktır."
                          );
                        }}
                      >
                        Açıklama
                      </Button>
                    );
                  } else return <div></div>;
                };

                const ifBasari = row.willUserTakeIFBasariBelgesi && (
                  <PdfExporter
                    size="small"
                    type={PDF_DOC_PROPS.if_basari.type}
                    school={
                      row.school_name +
                      " (" +
                      row.city +
                      "/" +
                      row.district +
                      ")"
                    }
                    name={row.name}
                  />
                );

                const ifKatilim = row.willUserTakeIFKatilimBelgesi && (
                  <PdfExporter
                    size="small"
                    type={PDF_DOC_PROPS.if_katilim_ogrenci.type}
                    school={
                      row.school_name +
                      " (" +
                      row.city +
                      "/" +
                      row.district +
                      ")"
                    }
                    name={row.name}
                  />
                );

                const ifResultText = if_2024_result.conclusion
                  ? IF_RESULT_TEXTS[if_2024_result.conclusion].short
                  : "";

                return (
                  <TableRow key={row.user_id + "user_id"}>
                    <TableCell align="center">{row.user_id}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    {/* <TableCell align="left">{row.email}</TableCell> */}
                    {user.role === 1 && (
                      <TableCell
                        align="center"
                        style={{ margin: 0, padding: 0 }}
                      >
                        {" "}
                        {row.class_id}
                      </TableCell>
                    )}
                    <TableCell align="center">{if_2024_result.score}</TableCell>
                    <TableCell align="center">
                      {if_2024_result.order_in_country}
                    </TableCell>
                    <TableCell align="center"> {ifResultText}</TableCell>

                    {user.role === 1 && (
                      <TableCell align="center">
                        {personalExamLink({
                          usr: row,
                          buttonName: "İl Finali",
                          size: "small",
                          season: "if_2324",
                        })}
                      </TableCell>
                    )}

                    {
                      <TableCell align="center">
                        {" "}
                        {(user.role === 1 || user.id === row.user_id) &&
                          (ifBasari ?? ifKatilim)}
                      </TableCell>
                    }
                    {
                      <TableCell align="center">
                        {" "}
                        {(user.role === 1 || user.id === row.user_id) &&
                          generateIfSEBF()}
                      </TableCell>
                    }

                    {/* {user.role === 1 && (
                        <TableCell align="center">
                          {row.is_paid ? "ÖDENDİ" : "Ödenmedi"}
                        </TableCell>
                      )} */}
                    {/* <TableCell align="center">
                        {!row.is_paid && user.role === 1 && (
                          <Checkbox
                            // name={c}
                            // checked={classes[c]}
                            onChange={(e) => {
                              let tempSelectedStudents = { ...selectedStudents };
                              if (e.target.checked) {
                                tempSelectedStudents[row.user_id] = true;
                              } else {
                                delete tempSelectedStudents[row.user_id];
                              }
    
                              setSelectedStudents(tempSelectedStudents);
                            }}
                          />
                        )}
                      </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {Object.keys(selectedStudents).length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <Button onClick={onPurchaseClickedForSelectedStudents}>
                {"Seçilen " +
                  Object.keys(selectedStudents).length +
                  " Yarışmacı İçin Ödeme Yap"}
              </Button>
            </div>
          )}
        </TableContainer>
      </Box>
    </Box>
  );

  const coach_belge_render = () => {
    if (user.role === 1) {
      if (willCoachTakeSuccessDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.if_basari_ogretmen.type}
            school={
              user.school_name + " (" + user.city + "/" + user.district + ")"
            }
            name={user.name}
          />
        );
      } else if (willCoachTakeAttendanceDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.if_katilim_ogretmen.type}
            school={
              user.school_name + " (" + user.city + "/" + user.district + ")"
            }
            name={user.name}
          />
        );
      }
    } else {
      return null;
    }
  };

  const school_belge_render = () => {
    if (user.role == 1) {
      if (willSchoolTakeSuccessDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.if_basari_okul.type}
            school={user.city + " / " + user.district}
            name={user.school_name}
          />
        );
      } else if (willSchoolTakeAttendanceDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.if_katilim_okul.type}
            school={user.city + " / " + user.district}
            name={user.school_name}
          />
        );
      }
    }
  };

  const ifConclusion = user?.exam_results?.find(
    (er) => er.season === "2024_if"
  )?.conclusion;

  if (redirectPage) return <Redirect to={redirectPage} />;

  return (
    <div className={"card " + classNames.left_step}>
      <Typography size="small" color="white" font="tadao" weight="normal">
        İL FİNALLERİ
      </Typography>

      {didStudentPay && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {/* <p style={{ color: "red", fontSize: "1.2rem" }}>
              Lütfen sınava başlamadan önce okul/sınıf bilgilerinizi kontrol
              ediniz. Hata varsa düzeltiniz. Bilgilerinizin hatalı olması
              durumunda diskalifiye edilebilirsiniz.
            </p> */}

          {personalExamLink({
            usr: user,
            buttonName: "İl Finalleri Sınavı",
            season: "if_2324",
          })}
        </div>
      )}
      {/* {detailedUpdatesLink("İl Finalleri Sonuçları")} */}

      {studentTableIF}
      {/* {user.role === 0 && objectionSection(user)} */}
      {user.role === 1 && coach_belge_render()}
      {user.role === 1 && school_belge_render()}
      {/* {[1, 3].includes(ifConclusion) && (
          <>
            <hr width="100%" color="white" style={{ marginTop: 50 }} />
            <Button
              background="orange"
              style={{
                width: 140,
                height: 50,
                fontSize: "1.3rem",
              }}
              onClick={()=>onDisclaimButtonPress('2024_if')}
            >
              Feragat Et
            </Button>
            <span style={{ fontStyle: "italic", color: "grey", marginTop: 10 }}>
              (Herhangi bir sebeple Bölge Finalleri Sınavı'na katılamayacaksanız
              feragat butonuna basarak hakkınızı sırada bekleyen arkadaşınıza
              devredebilirsiniz. Bu durumda Bölge Finalleri Sınavı'na
              katılamazsınız. Sizin yerinize okulunuzdan sıralaması en yüksek
              olan aday sınava katılır. En geç 15 Mart Cuma günü saat 23.59'a
              kadar hakkınızdan feragat edebilirsiniz. Feragat etmeniz halinde
              bu işlemi geri alamazsınız.)
            </span>
          </>
        )} */}
      {/* {willUserTakeBasariBalgesi && (
          <PdfExporter
            type={PDF_DOC_PROPS.if_katilim_ogrenci.type}
            school={
              user.school_name + " (" + user.city + "/" + user.district + ")"
            }
            name={user.name}
          />
        )} */}

      {/* {willUserTakeSEBF && (
          <PdfExporterSEBF_IF
            type={PDF_DOC_PROPS.if_sebf.type}
            school={user.school_name}
            name={user.name}
            fileNumber={"TZVSMP21 il/" + user.id}
          />
        )} */}

      {/* {willUserTakeSEBF_later && <span style={{ color: "white" }}>(Sosyal Etkinlik Bilgilendirme Formunuz Bölge Finalleri Sınavından sonra hazırlanacaktır.)</span>} */}
      {/* <div className={classNames.resultTextStyle}>
          {il_fin_conclusion_long_text}
        </div> */}
    </div>
  );
};
