import { Button, Checkbox, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { schoolCities } from "../../common/constants";
import Input from "../../components/ui/input/input";
import Label from "../../components/ui/label/label";
import Radio from "@material-ui/core/Radio";

import { getDistrictSchools as getDistrictSchoolsAction } from "../../store/actions/generic";
import { addSchool } from "../../store/actions/generic";
import { ADD_SCHOOL } from "../../store/actionTypes";

import MaterialBackDrop from "../../components/ui/loading/materialBackDrop";
import { insertQuestion, firebase_do_something } from "../../firebaseMethods";

const AdminOperations = () => {
  const [schoolName, setSchoolName] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [schoolPrivateType, setSchoolPrivateType] = useState(1);

  const dispatch = useDispatch();

  const reloadDistrictSchools = (city, district) => {
    dispatch(getDistrictSchoolsAction(city, district));
  };

  const schoolCityChooser = (
    <div style={{ flex: 2, marginRight: "10px" }}>
      <Input
        name="city"
        placeholder="Seçiniz"
        label="Okul İli"
        select
        value={city}
        onChange={(e, newItem) => {
          setCity(newItem ? newItem.value : "");
          setDistrict("");
        }}
        options={Object.keys(schoolCities)
          .sort((a, b) => a.localeCompare(b))
          .map((cityName) => {
            return { value: cityName, title: cityName };
          })}
      />
    </div>
  );

  const schoolDistrictChooser = (
    <div style={{ flex: 2, marginRight: "10px" }}>
      <Input
        name="district"
        placeholder="Seçiniz"
        label="Okul İlçesi"
        select
        value={district}
        onChange={(e, newItem) => {
          const district = newItem ? newItem.value : "";
          setDistrict(district);
          reloadDistrictSchools(city, district);
        }}
        options={
          schoolCities[city] &&
          schoolCities[city]
            .sort((a, b) => {
              return a.localeCompare(b);
            })
            .map((districtName) => {
              return { value: districtName, title: districtName };
            })
        }
      />
    </div>
  );

  const schoolNameInput = (
    <div style={{ flex: 3, marginRight: "10px" }}>
      <Input
        name="school"
        placeholder="Okul Adı"
        label="Okul"
        onChange={(e) => {
          setSchoolName(e.target.value.toLocaleUpperCase());
        }}
        value={schoolName}
      />
    </div>
  );

  const schoolTypeChooser = (
    <Label label="Kayıt Türü">
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ color: "white", marginBottom: 20, fontSize: "1.3rem" }}>
          <Radio
            style={{ color: "white" }}
            checked={schoolPrivateType === 1}
            onChange={() => {
              setSchoolPrivateType(1);
            }}
          />{" "}
          Özel
        </div>
        <div style={{ color: "white", marginBottom: 20, fontSize: "1.3rem" }}>
          <Radio
            style={{ color: "white" }}
            checked={schoolPrivateType === 0}
            onChange={() => {
              setSchoolPrivateType(0);
            }}
          />{" "}
          Devlet
        </div>
      </div>
    </Label>
  );

  const addSchoolButton = (
    <div style={{ flex: 1 }}>
      <Button
        style={{ marginLeft: "10px", height: "50px" }}
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatch(
            addSchool({
              private_type: schoolPrivateType,
              name: schoolName,
              district: district,
              city: city,
            })
          );
        }}
      >
        Ekle
      </Button>
    </div>
  );

  const doSmthButton = (
    <div style={{ flex: 1 }}>
      <Button
        style={{ marginLeft: "10px", height: "50px" }}
        variant="contained"
        color="secondary"
        onClick={firebase_do_something}
      >
        BASMAYINIZ!!!
      </Button>
    </div>
  );

  return (
    <div
    // style={{
    //   width: "100%",
    //   display: "flex",
    //   alignItems: "flex-end",
    // }}
    >
      {/* {doSmthButton} */}
      {schoolCityChooser}
      {schoolDistrictChooser}
      {schoolNameInput}
      {schoolTypeChooser}
      {addSchoolButton}
    </div>
  );
};

export default AdminOperations;
