import React from "react";
import classNames from "./style.module.css";
import Typography from "../../../../components/ui/typography";

const events = [
  {
    title: "Kayıt Süresi",
    date: "20 Eylül 2023 - 9 Şubat 2024",
  },
   {
     title: "Deneme Sınavı",
     subtitle: "(İnternet üzerinden)",
     date: "20 Ocak 2024 Cumartesi",
     time: "13:00-13.45",
     /* results: [
       {
         title:
           "Sınav ekranlarının tanıtımı için yapılacaktır. Sonuç açıklanmayacaktır.",
       },
     ], */
   },
  {
    title: "İl Finalleri Sınavı",
    subtitle: "(İnternet üzerinden)",
    date: "10 Şubat 2024 Cumartesi",
    time: "13.00-14.30",
    results: [
      {
        title: "Sorulara/Cevaplara İtirazlar: ",
        date: "12-15 Şubat 2024",
      },
      {
        title: "Puanların Açıklanması: ",
        date: "20 Şubat 2024",
        time: "Salı",
      },
      {
        title: "Puanlamaya İtirazlar: ",
        date: "20-22 Şubat 2024",
      },
      {
        title: "Bölge Finalleri Sınavına Katılacakların İlan Edilmesi: ",
        date: "28 Şubat 2024",
        time: "Çarşamba",
      },
    ],
  },
  {
    title: "Bölge Finalleri Sınavı",
    subtitle: "(İnternet üzerinden)",
    date: "16 Mart 2024 Cumartesi",
    time: "13.00-14.30",
    results: [
      {
        title: "Sorulara/Cevaplara İtirazlar: ",
        date: "18-21 Mart 2024",
      },
      {
        title: "Puanların Açıklanması: ",
        date: "26 Mart 2024",
        time: "Salı",
      },
      {
        title: "Puanlamaya İtirazlar: ",
        date: "26-28 Mart 2024",
      },
      {
        title: "Türkiye Finalleri Sınavına Katılacakların İlan Edilmesi: ",
        date: "3 Nisan 2024",
        time: "Çarşamba",
      },
    ],
  },
  {
    title: "Türkiye Finalleri Sınavı",
    subtitle: "(Ankara'da Yazılı Sınav)",
    date: "4 Mayıs 2024 Cumartesi",
    time: "",
    explanations: [
      "İlkokullar (1-2-3-4. sınıflar): 10.30-11.30",
      "Ortaokullar (5-6-7-8. sınıflar): 13.30-14.30",
      "Liseler (Hazırlık/9-10-11-12. sınıflar): 16.30-17.30",
    ],
    results: [
      // {
      //   title: "İlk Sonuçlar: ",
      //   date: "14 Mayıs 2024",
      //   time: "Salı",
      // },
      {
        title: "Sorulara/Cevaplara İtirazlar: ",
        date: "6-8 Mayıs 2024",
      },
      // {
      //   title: "Sonuçlar: ",
      //   date: "22 Mayıs 2024",
      //   time: "Çarşamba",
      // },
    ],
  },
  // {
  //   title: "Ödül Töreni",
  //   subtitle: "(Ankara'da Yapılacaktır.)",
  //   date: "3 Haziran 2023 Cumartesi",
  //   time: "",
  //   explanations: [
  //     "Şampiyona'nın ödül töreni 3 Haziran 2023 Cumartesi günü saat 13.00'te Ankara'da, ODTÜ'de gerçekleştirilecektir. Derece alan takımlara tören davet mektubu önümüzdeki günlerde iletilecektir. ",
  //     "Yer: Orta Doğu Teknik Üniversitesi, Fizik Bölümü (U3 Amfisi)",
  //     "Adres: Üniversiteler Mahallesi, No:1 ODTÜ Fizik Bölümü, 06800 Çankaya",
  //     "Tarih: 3 Haziran 2023, Ankara",
  //     "Saat: 13.00",
  //   ],
  //   results: [
  //     {
  //       title: "Düşünce Şenliği: ",
  //       date: "7 Haziran 2023",
  //       time: "İnternet Üzerinden",
  //     },
  //     // {
  //     //   title: "Ödül Töreni: ",
  //     //   date: "4 Haziran 2022",
  //     //   time: "14:00",
  //     // },
  //   ],
  // },
  // {
  //   title: "Düşünce Şenliği",
  //   subtitle: "(Zoom üzerinden yapılacaktır)",
  //   date: "7 Haziran 2023 Çarşamba",
  //   explanations: [
  //     "Düşünce şenliğine katılacak kişilere zoom davet linki e-posta ile gönderilmiştir.",
  //   ],
  // },
];

const Schedule = () => (
  <div className={classNames.schedule}>
    <Typography color="orange" size="bigger">
      Yarışma Takvimi
    </Typography>
    <div className={"card " + classNames.card}>
      <table style={{ width: "100%" }}>
        <tbody className={classNames.schedule_table}>
          {events.map((event, key) => (
            <tr key={key}>
              <td>
                <Typography
                  color="orange"
                  font="merel"
                  weight="bold"
                  className="inline"
                >
                  {event.title}
                </Typography>
                {event.subtitle && (
                  <Typography className="inline" color="grey" weight="normal">
                    {event.subtitle}
                  </Typography>
                )}

                {event.explanations?.map((e) => {
                  return (
                    <p
                      style={{ width: 700, fontSize: 20 }}
                      className={classNames.calendarExplanations}
                    >
                      {e}
                    </p>
                  );
                })}
              </td>
              <td>
                <Typography
                  color="white"
                  font="tadao"
                  weight="normal"
                  className="inline"
                >
                  {event.date}
                </Typography>
                {event.time && (
                  <Typography
                    className="inline"
                    color="grey"
                    weight="normal"
                    font="tadao"
                  >
                    {event.time}
                  </Typography>
                )}
                {event.results &&
                  event.results.map((result, key) => (
                    <Typography
                      className={classNames.result}
                      key={key}
                      colSpan="2"
                      color="orange"
                      font="merel"
                      size="small"
                      weight="normal"
                    >
                      {result.title}
                      <span className="white">{result.date}</span>
                      {result.time && (
                        <span className="grey"> {result.time}</span>
                      )}
                    </Typography>
                  ))}
              </td>
            </tr>
          ))}

          <p style={{ color: "white" }}></p>
          <p style={{ color: "white" }}></p>
        </tbody>
      </table>
    </div>
  </div>
);

export default Schedule;
