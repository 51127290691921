import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React from "react";
import { PDF_DOC_PROPS } from "../../common/constants";
import Button from "../../components/ui/button";

var pixelWidth = require("string-pixel-width");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const generatePDF = async (property) => {
  const width_school = pixelWidth(property.school, { size: 15 });
  const width_name = pixelWidth(property.name, { size: 33 });

  let docDefinition = {
    pageOrientation: "landscape",
    pageSize: "A4",
    pageMargins: [0, -10, 0, 0],
    background: {
      image: property.background_image,
      fit: [842, 595.28],
      pageMargins: [40, 500, 40, 40], // [left, top, right, bottom]
    },
    content: [
      {
        text: property.name.toLocaleUpperCase('TR'),
        fontSize: 33,
        // font: "Tadao",
        // absolutePosition: { x: 52, y: 295 },
        alignment: "center",
        margin: [40, 326, 40, 12],
        color: "#f19220",
      },

      {
        text: property.school,
        fontSize: 15,
        font: "Merel",
        // absolutePosition: { x: 52, y: 337 },
        margin: [40, 0, 40, 0],
        alignment: "center",
        color: "#1b3d56",
      },
    ],
  };

  pdfMake.fonts = {
    Tadao: {
      normal: "Tadao-ExtraBold.otf",
      extrabold: "Tadao-ExtraBold.otf",
      bold: "Tadao-ExtraBold.otf",
      italics: "Tadao-ExtraBold.otf",
      bolditalics: "Tadao-ExtraBold.otf",
    },
    Roboto: {
      normal: "Tadao-ExtraBold.otf",
      bold: "Tadao-ExtraBold.otf",
      italics: "Tadao-ExtraBold.otf",
      bolditalics: "Tadao-ExtraBold.otf",
    },
    Merel: {
      normal: "Merel-Bold.otf",
      bold: "Merel-Bold.otf",
      italics: "Merel-Bold.otf",
      bolditalics: "Merel-Bold.otf",
    },
  };

  pdfMake.createPdf(docDefinition).download(property.file_name);
};

const PdfExporter = (props) => {
  // https://www.base64-image.de/

  const isSmall = props.size === "small";

  if (props.name === "") {
    return null;
  } else {
    const docProps = PDF_DOC_PROPS[props.type];
    return (
      <Button
        style={{
          background: "green",
          fontSize: isSmall ? 12 : 20,
          marginLeft: 0,
          marginTop: isSmall? 0 :  12,
          marginBottom: 0,
          height: isSmall ? 25 : 40
        }}
        onClick={() => {
          generatePDF({
            name: props.name,
            school: props.school,
            background_image: docProps.background_image,
            file_name: docProps.file_name,
          });
        }}
      >
        {docProps.button_text}
      </Button>
    );
  }
};
export default PdfExporter;
