import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { signUp as signUpAction } from "../../store/actions/auth";
import { getDistrictSchools as getDistrictSchoolsAction } from "../../store/actions/generic";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import classNames from "./signup.module.css";
import Typography from "../../components/ui/typography";
import Input from "../../components/ui/input/input";
import Label from "../../components/ui/label/label";
import Button from "../../components/ui/button";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import { SIGN_IN, GET_DISTRICT_SCHOOLS } from "../../store/actionTypes";
import Loading from "../../components/ui/loading/loading";
import { schoolCities, days, months, years } from "../../common/constants";
import { Grid } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import AnnouncementModal from "../../components/ui/announcementModal/announcementModal";

const IndividualSignup = (props) => {
  const [params, setParams] = useState({
    name: "",
    surname: "",
    phone: "",
    // tc: "",
    email: "",
    email_2: "",
    schoolId: "",
    classId: "",
    // state: false,
    password: "",
    confirmPassword: "",
    attended: false,
    city: "",
    district: "",
    birthDay: "",
    birthMonth: "",
    birthYear: "",
    birthDate: "",
    role: 1,
    parent_permission: false, // veli izni
    storingInfoPermission: false,
    coachClasses: {},
  });

  const setParam = (key, value) => {
    setParams({
      ...params,
      [key]: value,
    });
  };
  const districtSchools = useSelector((state) => state.generic.districtSchools);

  const setInput = ({ target: { name, value } }) => {
    let newVal = value;
    if (name === "email" || name === "email_2")
      newVal = value.toLocaleLowerCase("tr-TR");
    setParam(name, newVal);
  };

  const setState = (e, state) => {
    e.preventDefault();
    setParam("state", state);
  };

  const setAttended = (e, attended) => {
    e.preventDefault();
    setParam("attended", attended);
  };

  const setBirthDate = (birthDay, birthMonth, birthYear) => {
    let dateForState = "";
    if (birthDay && birthMonth && birthYear) {
      const birthMontStr =
        birthMonth < 10 ? "0" + birthMonth : birthMonth.toString();
      const birthDayStr = birthDay < 10 ? "0" + birthDay : birthDay.toString();
      let dateString = birthYear + "-" + birthMontStr + "-" + birthDayStr;
      if (!isNaN(Date.parse(dateString))) dateForState = dateString;
    }

    setParams({
      ...params,
      birthDay,
      birthMonth,
      birthYear,
      birthDate: dateForState,
    });
  };

  const dispatch = useDispatch();

  const signUp = useCallback(() => {
    let sentParams = {
      name: params.name.trim() + " " + params.surname.trim(),
      phone: params.phone,
      email: params.email.trim(),
      password: params.password,
      school_id: params.schoolId,
      attended: params.attended,
      role: params.role,
      //
    };

    if (params.role === 0) {
      sentParams.birthdate = params.birthDate;
      sentParams.class_id = params.classId;
    }
    if (params.role === 1) sentParams.coachClasses = params.coachClasses;

    return dispatch(signUpAction(sentParams));
  }, [params, dispatch]);

  const onSignUp = (e) => {
    e.preventDefault();

    if (params.name.trim().length < 2)
      alert("Lütfen isminizi eksiksiz giriniz.");
    else if (params.surname.trim().length < 2)
      alert("Lütfen soyisminizi eksiksiz giriniz.");
    else if (params.birthDate === "" && params.role === 0)
      alert("Lütfen geçerli bir doğum tarihi giriniz.");
    // else if (isNaN(params.tc) || params.tc === "")
    //   alert("Lütfen geçerli bir TC Kimlik No giriniz.");
    else if (params.phone == "" || isNaN(params.phone.charAt(14)))
      alert("Lütfen telefon numaranızı eksiksiz giriniz.");
    else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(params.email))
      alert(
        "Hatalı bir eposta adresi yazdınız. Lütfen eposta adresinizi tekrar yazınız."
      );
    else if (params.email.trim() !== params.email_2.trim())
      alert("Girdiğiniz e-posta adresleri birebir aynı olmalı.");
    else if (params.password.length < 6)
      alert("Şifre en az 6 karakter olmalı.");
    else if (params.confirmPassword !== params.password)
      alert("Girdiğiniz şifreler birbiriyle eşleşmiyor!");
    else if (params.district.length === 0)
      alert("Lütfen okulunuzun bulunduğu ilçeyi seçiniz.");
    else if (params.schoolId === "") alert("Lütfen okulunuzu seçiniz.");
    else if (params.role == 0 && params.classId === "")
      alert("Lütfen sınıf seçiniz.");
    // else if (params.role == 1 && params.classId === "") alert("Lütfen sınıf seçiniz.");
    else if (params.role == 0 && !params.parent_permission)
      alert(
        "Şampiyonaya kayıt olabilmek için velinizden izin almanız ve ilgili kutucuğu işaretlemeniz gerekmektedir."
      );
    else if (!params.storingInfoPermission)
      alert(
        "Bilgilerinizin geçici olarak saklanabileceğine dair izniniz gerekiyor."
      );
    else {
      signUp().then((res) => {
        if (res.status == "ok") props.history.push("/yonetim");
        else if (res.status == "already_exists") {
          alert(
            "Kayıt başarılı. Email ve şifreniz ile sisteme giriş yapabilirsiniz."
          );
          props.history.push("/giris");
        } else alert("HATA: Kayıt başarısız.");
      });
    }
  };

  const reloadDistrictSchools = (city, district) => {
    dispatch(getDistrictSchoolsAction(city, district));
  };

  const loading = useSelector((state) => state.loading[SIGN_IN]);
  const loadingSchoolList = useSelector(
    (state) => state.loading[GET_DISTRICT_SCHOOLS]
  );

  const pageLogo = (
    <div className={classNames.logo_wide}>
      <img src="/assets/images/logo-w.png" alt="home-logo" />
    </div>
  );

  const explanationParagraphs = (
    <div className={["card", classNames.read_before_signup].join(" ")}>
      <Typography
        color="lime"
        font="bold"
        size="smaller"
        style={{ paddingBottom: 8 }}
      >
        Kaydolmadan önce okuyunuz
      </Typography>
      <div className="white fw_normal tadao">
        <p>
          -Şampiyona <span className="orange">“İlkokul (3-4. sınıflar)”</span>,{" "}
          <span className="orange">“Ortaokul (5-6-7-8. sınıflar)”</span> ve{" "}
          <span className="orange">“Lise (9-10-11-12. sınıflar)”</span> olmak
          üzere üç farklı kategoride yapılır
        </p>
        <p>
          -Okullar <span className="orange">tüm kategorilerde</span> ya da uygun
          gördükleri kategorilerde şampiyonaya katılabilir.
        </p>
        <p>
          Devam etmeden önce lütfen <span className="orange">KOŞULLAR</span>{" "}
          bölümünü okuyunuz.
        </p>
      </div>
    </div>
  );

  const caution = (
    <div>
      <Typography
        color="white"
        size="small"
        style={{ paddingBottom: 10, paddingTop: 10 }}
      >
        {/* (Tüm alanların doldurulması zorunludur.) */}
        2023-2024 sezonu kayıtları sona ermiştir. Şu an aktif yarışmamız olan
        OYUN 2024 sayfasına gitmek için lütfen{" "}
        <a href="https://oyun.tzv.org.tr/" target="blank">
          tıklayınız
        </a>
      </Typography>
    </div>
  );

  const roleChooser = (
    <Label label="Kayıt Türü">
      <div className={classNames.role_type}>
        {/* <div className={classNames.roleChoserRadio}>
          <Radio
            style={{ color: "white" }}
            checked={params.role === 0}
            onChange={(e) => {
              setParam("role", 0);
            }}
          />{" "}
          Yarışmacı Öğrenci
        </div> */}
        <div className={classNames.roleChoserRadio}>
          <Radio
            style={{ color: "white" }}
            checked={params.role === 1}
            onChange={(e) => {
              setParam("role", 1);
            }}
          />{" "}
          Sorumlu Öğretmen
        </div>
      </div>
    </Label>
  );

  const nameSurnameInputs = (
    <div className={classNames.nameSurnameInputs}>
      <Input
        name="name"
        placeholder="Adı"
        label={params.role === 0 ? "Yarışmacı Adı" : "Öğretmen Adı"}
        onChange={setInput}
        value={params.name}
      />
      <Input
        name="surname"
        placeholder="Soyadı"
        label={params.role === 0 ? "Yarışmacı Soyadı" : "Öğretmen Soyadı"}
        onChange={setInput}
        value={params.surname}
      />
    </div>
  );

  const birthdateChooser = params.role === 0 && (
    <div>
      <Label label="Doğum Tarihi">
        <Grid container spacing={1}>
          <Grid xs={12} sm={4} item>
            <Input
              name="birthDay"
              placeholder="Seçiniz"
              select
              value={params.birthDay}
              onChange={(e, newItem) => {
                const day = newItem ? newItem.value : "";
                setBirthDate(day, params.birthMonth, params.birthYear);
              }}
              options={days.map((d) => {
                return { value: d.toString(), title: d.toString() };
              })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {" "}
            <Input
              name="birthMonth"
              placeholder="Seçiniz"
              select
              value={params.birthMonth}
              onChange={(e, newItem) => {
                const month = newItem ? newItem.value : "";
                setBirthDate(params.birthDay, month, params.birthYear);
              }}
              options={Object.keys(months).map((m_key) => ({
                value: m_key.toString(),
                title: months[m_key],
              }))}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <Input
              name="birthYear"
              placeholder="Seçiniz"
              select
              value={params.birthYear}
              onChange={(e, newItem) => {
                const year = newItem ? newItem.value : "";
                setBirthDate(params.birthDay, params.birthMonth, year);
              }}
              options={years.map((d) => {
                return { value: d.toString(), title: d.toString() };
              })}
            />
          </Grid>
        </Grid>
      </Label>
      {/* <Typography
        font="tadao"
        weight="normal"
        size="smaller"
        color="grey"
        align="left"
      >
        Velinin/Yarışmacının açık rızası ile alınan bu bilgiler 3. şahıslarla
        paylaşılmayacaktır, etkinlik sonunda silinecektir.
      </Typography> */}
    </div>
  );
  const tcInput = (
    <div>
      <Input
        name="tc"
        placeholder="TC Kimlik No"
        label="TC Kimlik No"
        onChange={setInput}
        value={params.tc}
        mask="99999999999"
      />
      {/* <Typography
        font="tadao"
        weight="normal"
        size="smaller"
        color="grey"
        align="left"
      >
        Velinin/Yarışmacının açık rızası ile alınan bu bilgiler 3. şahıslarla
        paylaşılmayacaktır, etkinlik sonunda silinecektir.
      </Typography> */}
    </div>
  );

  const phoneInput = (
    <div>
      <Input
        name="phone"
        placeholder="Telefon"
        label="Telefon"
        onChange={setInput}
        value={params.phone}
        mask="(999)-999-99-99"
      />
      {/* <Typography
        font="tadao"
        weight="normal"
        size="smaller"
        color="grey"
        align="left"
      >
        Velinin/Yarışmacının açık rızası ile alınan bu bilgiler 3. şahıslarla
        paylaşılmayacaktır, etkinlik sonunda silinecektir.
      </Typography> */}
    </div>
  );

  const emailInput = (
    <Input
      name="email"
      placeholder="Eposta"
      label="Eposta"
      onChange={setInput}
      value={params.email}
    />
  );

  const emailInput_2 = (
    <Input
      name="email_2"
      placeholder="Eposta Tekrar"
      label="Eposta Tekrar"
      onChange={setInput}
      value={params.email_2}
    />
  );

  const passwordInput = (
    <Input
      name="password"
      placeholder="Şifre"
      label="Şifre"
      type="password"
      onChange={setInput}
      value={params.password}
    />
  );

  const passwordConfirmInput = (
    <Input
      name="confirmPassword"
      placeholder="Şifre Tekrar"
      type="password"
      label="Şifre Tekrar"
      onChange={setInput}
      value={params.confirmPassword}
    />
  );

  const schoolFormHeader = (
    <Typography
      color="orange"
      size="small"
      font="merel"
      style={{ paddingBottom: 10, paddingTop: 30 }}
    >
      Okul Bilgileri
    </Typography>
  );

  const schoolCityChooser = (
    <Input
      name="city"
      placeholder="Seçiniz"
      label="Okul İli"
      select
      value={params.city}
      onChange={(e, newItem) => {
        setParams({
          ...params,
          district: "",
          city: newItem ? newItem.value : "",
          schoolId: "",
        });
      }}
      options={Object.keys(schoolCities)
        .sort((a, b) => a.localeCompare(b))
        .map((cityName) => {
          return { value: cityName, title: cityName };
        })}
    />
  );
  const schoolDistrictChooser = (
    <Input
      name="district"
      placeholder="Seçiniz"
      label="Okul İlçesi"
      select
      value={params.district}
      onChange={(e, newItem) => {
        setParams({
          ...params,
          district: newItem ? newItem.value : "",
          schoolId: "",
        });
        const district = newItem ? newItem.value : "";
        reloadDistrictSchools(params.city, district);
      }}
      options={
        schoolCities[params.city] &&
        schoolCities[params.city]
          .sort((a, b) => {
            return a.localeCompare(b);
          })
          .map((districtName) => {
            return { value: districtName, title: districtName };
          })
      }
    />
  );
  const schoolChooser = (
    <Input
      name="schoolId"
      placeholder="Seçiniz"
      label="Okul İsmi"
      select
      value={params.schoolId}
      onChange={(e, newItem) => {
        // if(params.role ===0 && !params.classId)
        // alert("Lütfen önce sınıfınızı seçiniz.");
        // else
        setParams({
          ...params,
          schoolId: newItem ? newItem.value : "",
        });
      }}
      options={districtSchools.map((s) => ({ value: s.id, title: s.name }))}
      // .filter(s => {
      //   if (params.role === 1)
      //     return true;

      //     // isminde lkokul geçiyorsa kesin ilkokul, ortaokul geçiyorsa kesin ortaokul, lise geçiyorsa kesin lise, diğer durumlarda ise tüm sınıflar..
      //     let class_ids = [];
      //     if(s.name.indexOf("İLKOKUL")>-1) class_ids = ['3', '4'];
      //     else if(s.name.indexOf("ORTAOKUL")>-1) class_ids = ['5', '6', '7', '8'];
      //     else if(s.name.indexOf("LİSE")>-1) class_ids = ['9', '10', '11', '12'];
      //     else class_ids = ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12']; // Kolej, Bilsem vs. tüm sınıflara yönelik olacak.

      //     return class_ids.includes(params.classId);

      // })
    />
  );

  const studentClassChooser = (
    <Input
      name="classId"
      placeholder="Sınıfınız"
      label="Sınıfınız"
      select
      value={params.classId}
      onChange={(e, newItem) => {
        setParams({
          ...params,
          classId: newItem ? newItem.value : "",
        });
      }}
      options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((d) => {
        if (d === 9)
          return { value: d.toString(), title: "Hazırlık / 9.Sınıf" };
        else return { value: d.toString(), title: d.toString() + ". Sınıf" };
      })}
    />
  );

  const coachClassChooser = false && (
    <>
      <Typography
        font="tadao"
        weight="normal"
        size="smaller"
        color="white"
        align="left"
      >
        Sınıf
      </Typography>
      <div>
        <Typography font="tadao" weight="normal" size="smaller" color="grey">
          Her sınıf için
          <span className="orange"> yalnızca 1 öğretmen </span>
          kaydına izin verilmektedir.
        </Typography>
      </div>
      <div style={{ paddingTop: 20, margin: 8 }}>
        <Grid
          container
          spacing={2}
          style={{
            backgroundColor: "white",
            borderRadius: "12px",
            padding: "7px",
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((s) => {
            const isThisBoxChecked = params.coachClasses[s] ? true : false;
            return (
              <Grid
                style={{ textAlign: "left" }}
                xs={6}
                item
                key={"class_checker_" + s}
              >
                <Checkbox
                  checked={isThisBoxChecked}
                  onChange={() => {
                    let tempCoachClasses = { ...params.coachClasses };
                    tempCoachClasses[s] = !isThisBoxChecked;
                    setParam("coachClasses", tempCoachClasses);
                  }}
                />
                {s === 9 ? "Hazırlık / 9. Sınıf" : s + ". Sınıf"}
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );

  const attendedBeforeChooser = (
    <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
      <Label label="Daha Önce TZV'nin Düzenlediği Zeka Oyunları Yarışmalarına Katıldınız mı?">
        <div className={classNames.school_type}>
          <Button
            className={classNames.school_type_button}
            onClick={(e) => setAttended(e, true)}
            background={!params.attended ? "white" : "orange"}
            color={!params.attended ? "black" : "white"}
          >
            Evet
          </Button>
          <Button
            className={classNames.school_type_button}
            onClick={(e) => setAttended(e, false)}
            background={!params.attended ? "orange" : "white"}
            color={!params.attended ? "white" : "black"}
          >
            Hayır
          </Button>
        </div>
      </Label>
    </div>
  );
  const parentPermissionCheckBox = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "start",
        marginBottom: "20px",
      }}
    >
      <Checkbox
        checked={params.parent_permission}
        onChange={() => {
          setParam("parent_permission", !params.parent_permission);
        }}
        name="checkedB"
        style={{
          color: "white",
          padding: 0,
          marginRight: "20px",
          marginTop: "2px",
        }}
      />
      <Typography
        font="tadao"
        weight="normal"
        size="smaller"
        color="white"
        style={{ textAlign: "left" }}
      >
        {/* {" Velisi olduğum yarışmacının yarışmaya katılmasına izin veriyorum."} */}
        {
          " Yarışmaya velimin bilgisi ve izni dahilinde başvuruduğumu beyan ederim."
        }
      </Typography>
    </div>
  );

  const storingPersonalInfoPermissionCheckBox = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "start",
      }}
    >
      <Checkbox
        checked={params.storingInfoPermission}
        onChange={() => {
          setParam("storingInfoPermission", !params.storingInfoPermission);
        }}
        name="checkedB"
        style={{
          color: "white",
          padding: 0,
          marginRight: "20px",
          marginTop: "2px",
        }}
      />
      <Typography
        font="tadao"
        weight="normal"
        size="smaller"
        color="white"
        align="left"
      >
        Kategorileri belli etmek amacıyla bilgilerimin alınmasına, alınan
        bilgilerin üçüncü şahıslarla hiçbir ad altında paylaşılmamasına ve
        etkinliğin sona ermesiyle silinmesine onay veriyorum.
      </Typography>
    </div>
  );

  const signupButton = (
    <Button
      style={{ margin: "auto", marginTop: 30, marginBottom: 30 }}
      onClick={onSignUp}
    >
      Kaydet
    </Button>
  );
  const lastCautionText = (
    <Typography font="tadao" weight="normal" size="smaller" color="grey">
      Kayıt olarak yarışma
      <span className="orange"> şartnamesini </span>
      kabul etmiş olursunuz.
    </Typography>
  );

  const signInButton = (
    <Typography font="tadao" weight="normal" size="smaller" color="grey">
      Daha önce kayıt oldunuz mu?
      <Link to="/giris" className="orange">
        Giriş Yap
      </Link>
    </Typography>
  );

  const kayitHenuzBaslamadiUyarisi = (
    <div>
      <Typography font="tadao" weight="normal" size="smaller" color="grey">
        2020 kayıtları henüz başlamadı...
      </Typography>
    </div>
  );

  const signupEndedCaution = (
    <div style={{ maxWidth: 900 }}>
      {/* <Typography font="tadao" weight="normal" size="normal" color="grey">
       Kayıt süresi sona ermiştir.
      </Typography> */}
      {/* <Typography font="tadao" weight="normal" size="normal" color="white">
        <p>
          Türkiye 26. Zeka Oyunları Yarışması (Oyun 2021) ise TZV tarafından düzenlenen ayrı bir yarışmadır ve kayıtları devam etmektedir.
        </p>
        <p>Oyun 2021 kayıt sayfasına ulaşmak için <a href="https://oyun.tzv.org.tr">tıklayınız.</a></p>
      </Typography> */}
    </div>
  );

  return (
    <div className={classNames.signup}>
      {pageLogo}
      <div className={classNames.sign_up_container}>
        {signupEndedCaution}
        <div className={classNames.sign_up_form_container}>
          {(loading || loadingSchoolList) && <Loading />}
          <div className={["card", classNames.signup_form].join(" ")}>
            {caution}
            {/* {roleChooser}
            <AnnouncementModal title="ÖĞRENCİ KAYITLARI KAPANMIŞTIR" body= "Bu ekrandan sadece sorumlu öğretmen kayıtları yapılmaktadır."
           />
            {nameSurnameInputs}
            {birthdateChooser}

            {phoneInput}
            {emailInput}
            {emailInput_2}
            {passwordInput}
            {passwordConfirmInput}
            {schoolFormHeader}
            {schoolCityChooser}
            {schoolDistrictChooser}
            {schoolChooser}
            {params.role == 0 && studentClassChooser}
            {attendedBeforeChooser}
            {params.role == 0 && parentPermissionCheckBox}
            {storingPersonalInfoPermissionCheckBox}
            {signupButton}
            {lastCautionText} */}
          </div>
          {signInButton}
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

export default IndividualSignup;
